export const BtnShop = ({title, style, icon, onPress=()=>{}, href}) => {
      return <button  onClick={onPress} className={style}><a href={href}><i className={icon}></i> {title}</a> </button>
}

export const BtnProduct = ({title, style, icon, onPress=()=>{}, href}) => {
      return <a onClick={onPress}  href={href} className={style}> {title? title : null} <i className={icon}></i></a> 
}

export const Menu = ({style, style1, e}) => {
      return  <li className={style}>
                  <a className={style1} href={e.path}>{e.name}</a>
              </li>
}
