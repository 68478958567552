import React, { useEffect, useRef } from 'react';

const CustomerChat = () => {
  const MessengerRef = useRef();
  useEffect(() => {
      MessengerRef.current.setAttribute('page_id', '123625687401689');
      MessengerRef.current.setAttribute('attribution', 'biz_inbox');
      MessengerRef.current.setAttribute('theme_color', '#ff7e29');
      MessengerRef.current.setAttribute('logged_in_greeting', 'สวัสดีคุณมีคำถามหรือไม่?');
      MessengerRef.current.setAttribute('logged_out_greeting', 'สวัสดีคุณมีคำถามหรือไม่?');

      window.fbAsyncInit = function () {
          window.FB.init({
              xfbml: true,
              version: 'v16.0',
          });
      };
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        // js.src = 'proxy/phuket-wine.onrender.com/js/xfbml.customerchat.js';
        js.src = '/js/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
  }, []);
  return (
      <>
          <div id="fb-root"></div>
          <div ref={MessengerRef} id="fb-customer-chat" className="fb-customerchat"></div>
      </>
  );
};

export default CustomerChat;

