import axios from 'axios';
const STORE = '@497nqjdn';
const API_Endpoint = 'https://apilinemyshop.cyclic.app/api';

//กรอกข้อมูลตามที่ต้องการ
export const filterPro = async (el) => {
    try {
    const response = await axios.get(`${API_Endpoint}/filter`, {
    params:{
        store: STORE,
        limit: 500,
        page: 1,
        shopId: 532649,
        sortType: el,  // sortType >>>>>> "POPULAR" : "ยอดนิยม", "CREATED_TIME": "ล่าสุด", "PRICE_LOW_TO_HIGH": "ราคาต่ำไปสูง" , "PRICE_HIGH_TO_LOW": "ราคาสูงไปต่ำ", 
        sortSoldOutType: 'NONE',
        isGift: false
        }
    });
    if(response.status === 200){
      const products = response.data;
      return products
    }
// ทำอะไรกับข้อมูลสินค้าที่ได้รับมา
} catch (error) {
    console.error(error);
    }
};



//ดึงข้อมูลสินค้า
export const products = async () => {
    try {
      const response = await axios.get(`${API_Endpoint}/products`, {
        params: {
          STORE: STORE,
        },
      });
      if(response.status === 200){
        const products = response.data;
        return products
      }
      // ทำอะไรกับข้อมูลสินค้าที่ได้รับมา
    } catch (error) {
      console.error(error);
    }
};



//ดึงข้อมูลโปรโมชั่น
export const promotions = async () => {
    try {
      const response = await axios.get(`${API_Endpoint}/promotions`, {
        params: {
          searchId: STORE,
          limit: 20,
          productLimit: 20,
        }
      });
  
      if(response.status === 200){
        const products = response.data.promotions;
        return products
      }
    } catch (error) {
      console.error(error);
    }

};



//ดึงข้อมูลคอลเลกชั่น
export const CollectionId = async (collectionId) => {
  try {
    const response = await axios.get(`${API_Endpoint}/collection`, {
      params: {
        STORE: STORE,
        COLLECTION_ID: collectionId,
      }
    });
    if(response.status === 200){
      const products = response.data;
      return products
    }
  } catch (error) {
    console.error(error);
  }
};

export const collectionAll = async () => {
  try {
    const response = await axios.get(`${API_Endpoint}/collection`, {
      params: {
        STORE: STORE
      }
    });
    if(response.status === 200){
      const products = response.data;
      return products
    }
    // ทำอะไรกับข้อมูลสินค้าที่ได้รับมา
  } catch (error) {
    console.error(error);
  }
};

//ดึงข้อมูลโพสต์จากเฟสบุ๊ค
export const getfacebookpost = async () => {
  try {
    const result = await axios('https://script.google.com/macros/s/AKfycbx-cZSpX3VgqIXv239cbyyd9KmAe-ocWwdDjHsnzcZJhEQ2vPz3gsz-Q1cEOeT2uFgrPA/exec');
    if(result.status === 200){
      const products = result.data;
      return products
    } 
  } catch (error) {
    console.error(error);
  }

};

export const getProducts = async () => {
  try {
    const result = await axios('https://script.googleusercontent.com/macros/echo?user_content_key=eojVMc1C08LBADDSQ1qh40wH8P-D5GC1HQS76fKG-V6HRmy5MF4rqI8AGZEtxdwi4p9Z1TBknTzGRdV9zl37BY5-EFbrQJrAm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnHOOek4LXneVqS9Y4PysdPwG-0cUNRru3JRIOjTxhFqXVjueaPxC6ZMnf4BFo9iLm1Q0aiF_KiQpKzoyI7kasiFFZgUqU0TMddz9Jw9Md8uu&lib=MzDFSEitUTDwnWp2jrE30JJZN9eUrqTGN');
    if(result.status === 200){
      const products = result.data;
      return products
    } 
  } catch (error) {
    console.error(error);
  }

};


