import moment from 'moment'

export function setReverseFormatDate(date) {
      return moment.utc(date, 'YYYY-MM-DD').format('DD-MM-YYYY')
}

export function setFormatDateGMT(date) {
      return moment.utc(date).local().format('YYYY-MM-DDTHH:mm:ss')
}

export function setFormatDate(date) {
      return moment.utc(date).format('YYYY-MM-DD')
}

export function setFormatTime(date) {
      return moment.utc(date).format('HH:mm')
}

export function expireFormatNumDate(date) {
      return moment.utc(date).startOf('day').diff(moment().startOf('day'), 'days')
}

export function CalculatorNumberDateTime(dateReg, dateEx) {
      return moment(dateEx).diff(moment(dateReg), 'days');
}



