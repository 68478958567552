import { useLocation } from "react-router-dom";
import { CardBanner, CardProduct } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { menu } from "../mock";
import { useEffect, useState, useRef } from "react";
import { getProducts, products } from "../Api";
import Mixitup from 'mixitup';
import Loading from "../components/Loading";
import { useLayoutEffect } from "react";

function Prodoct() {
      const location = useLocation()
      const containerRef = useRef(null)
      const path = menu.filter(e=> e.path === location.pathname)[0]
      const [products, setProducts] = useState([])
      const [loading, setLoading] = useState(false)

      useLayoutEffect(() => {
            if (loading) {
              const containerEl = containerRef.current;
              var mixer = Mixitup(containerEl);
              return () => {
                mixer.destroy();
              };
            }
          }, [loading]);
        
          useEffect(() => {
            const fetchData = async () => {
              const result = await getProducts();
              setProducts(result);
              setTimeout(() => {
                setLoading(true);
              }, 2000);
            };
            fetchData();
          }, []);

      const collection = ['ทั้งหมด']
            products.forEach(e=> collection.push(e.type))
      const filteredArray = collection.filter((value, index, self) => self.indexOf(value) === index);  

  return (
      <>
      {loading ?
            <div>
                  <Header key={'biyqatx'} lo={location.pathname}/>
                  <CardBanner items={path}/>
                        <section className="total-body  sub-pages d-block">
                        <div className="products-div shop-page pt-5">
                        <div className="container"> 
                              <div className="inside-ps gallery" ref={containerRef}>
                                    <div className="list-work">
                                    <ul>
                                          {filteredArray.map(e =>{ return <li data-filter={e === 'ทั้งหมด'? 'all' : `.${e}`} className="active">{e}</li> })}
                                    </ul>
                                    </div>
                                    <div className="list-wrapper">
                                    <div className="row g-md-5 mt-0 row-cols-1 row-cols-md-2 row-cols-lg-4 gallery">
                                          {products.map((e, i) => { 
                                                return  <CardProduct key={i} items={e} col={`col mix all ${e.type} list-item`}/>
                                          })}
                                    </div>
                                    </div>
                              </div>
                        </div>
                        </div>

                        </section>
                  <Footer/>
            </div> : <Loading/>}
      </> 
  )
}

export default Prodoct;

