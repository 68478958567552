export const data = [
      {
          "id": 151301,
          "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_16792495671829917896c6a8357t1153778f",
          "collection_name": "ไวน์ขาว",
          "is_new": true
      },
      {
          "id": 152375,
          "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_1679249305865239047b5789404t11537689",
          "collection_name": "ไวน์แดง",
          "is_new": true
      },
      {
          "id": 152540,
          "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_167924982274890530722dd8532t1153788e",
          "collection_name": "ไวน์ชมพู",
          "is_new": true
      }
  ]

export const menu = [
      {name:'Home', path: '/'}, 
      {name:'เกี่ยวกับเรา', path: '/about'}, 
      {name:'สินค้า', path: '/product', path1: '/product-detail'}, 
      {name:'บล๊อก', path: '/blog', path1: '/blog-detail'}, 
      {name:'Faq', path: '/faq'}, 
      {name:'โปรโมชั่น', path: '/promotion'}, 
      {name:'ติดต่อเรา', path: '/contact'},
    //   {name:'สำหรับ Admin', path: '/adm'}
      
      ]

export const promotion = [
                              {     
                                    name: '10% Off Lorem Ipsum', 
                                    title: 'on all weekend sale'
                              }, 
                              {     name: '20% Off Lorem Ipsum', 
                                    title: 'on all weekend sale'
                              }, 
                              {     name: '30% Off Lorem Ipsum', 
                                    title: 'on all weekend sale'
                              }
                        ]

export const about = [
                              {     
                                    img: 'images/3262303.png', 
                                    title: 'การชำระเงิน ที่ปลอดภัย100% '
                              }, 
                              {     img: 'images/4497562.png', 
                                    title: 'สินค้าของแท้  มีคุณภาพ 100%'
                              }, 
                              {     img: 'images/2203124.png', 
                                    title: 'จัดส่งฟรี'
                              }, 
                              {     img: 'images/4088880.png', 
                                    title: ' รับประกันราคาดีที่สุด '
                              }
                        ]


export const protuctAll = [
      {
          "id": "1004161358",
          "productName": "อนานัส โรเซ่ (Ananus Rose)",
          "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_167924982274890530722dd8532t1153788e",
          "instantDiscount": 34,
          "price": "฿300",
          "discountedPrice": "฿200",
          "isInStock": true,
          "isGift": false,
          "__typename": "Product"
      },
      {
          "id": "1004161355",
          "productName": "อนานัส ชายนี่ไวท์ (Ananus Shiny White)",
          "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_16792495671829917896c6a8357t1153778f",
          "instantDiscount": 0,
          "price": "฿300",
          "discountedPrice": "฿300",
          "isInStock": true,
          "isGift": false,
          "__typename": "Product"
      },
      {
          "id": "1004161348",
          "productName": "ยานัด เรด ไวน์ (Red Pineapple Wine)",
          "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_1679249305865239047b5789404t11537689",
          "instantDiscount": 0,
          "price": "฿350",
          "discountedPrice": "300",
          "isInStock": true,
          "isGift": false,
          "__typename": "Product"
      },
      {
          "id": "1004161342",
          "productName": "ยานัด ไวท์ ไวน์ (White Pineapple Wine)",
          "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_16792485575196148336c6a7865t1153739d",
          "instantDiscount": 0,
          "price": "฿350",
          "discountedPrice": "฿350",
          "isInStock": true,
          "isGift": false,
          "__typename": "Product"
      },
      {
          "id": "1004161328",
          "productName": "ยานัด พรีเมี่ยม (YANAD PREMIUM)",
          "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_1679248082873574000b57a1215t115371c2",
          "instantDiscount": 0,
          "price": "฿500",
          "discountedPrice": "฿500",
          "isInStock": true,
          "isGift": false,
          "__typename": "Product"
      },
      {
            "id": "1004161351",
            "productName": "อนานัส โรเซ่ (Ananus Rose)",
            "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_167924982274890530722dd8532t1153788e",
            "instantDiscount": 34,
            "price": "฿300",
            "discountedPrice": "฿200",
            "isInStock": true,
            "isGift": false,
            "__typename": "Product"
        },
        {
            "id": "1004161352",
            "productName": "อนานัส ชายนี่ไวท์ (Ananus Shiny White)",
            "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_16792495671829917896c6a8357t1153778f",
            "instantDiscount": 0,
            "price": "฿300",
            "discountedPrice": "฿300",
            "isInStock": true,
            "isGift": false,
            "__typename": "Product"
        },
        {
            "id": "1004161343",
            "productName": "ยานัด เรด ไวน์ (Red Pineapple Wine)",
            "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_1679249305865239047b5789404t11537689",
            "instantDiscount": 0,
            "price": "฿350",
            "discountedPrice": "฿350",
            "isInStock": true,
            "isGift": false,
            "__typename": "Product"
        },
        {
            "id": "1004161344",
            "productName": "ยานัด ไวท์ ไวน์ (White Pineapple Wine)",
            "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_16792485575196148336c6a7865t1153739d",
            "instantDiscount": 0,
            "price": "฿350",
            "discountedPrice": null,
            "isInStock": true,
            "isGift": false,
            "__typename": "Product"
        },
        {
            "id": "1004161325",
            "productName": "ยานัด พรีเมี่ยม (YANAD PREMIUM)",
            "imgUrl": "https://obs.line-scdn.net:443/r/ect/ect/image_1679248082873574000b57a1215t115371c2",
            "instantDiscount": 0,
            "price": "฿500",
            "discountedPrice": null,
            "isInStock": true,
            "isGift": false,
            "__typename": "Product"
        }
  ]

export const protuct = [
                              {
                              "id": 1004161328,
                              "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 13% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต 100% สดจากไร่ที่ปลูกในจังหวัดภูเก็ต โดยหมักบ่มถึง 2 ปี จึงมีความละมุนในรสสัมผัส ไม่หวาน เข้ากันได้ดีกับอาหารซีฟู้ด Quantity: 750 Ml. Alcohol: 13% Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. After 2 years of fermentation, it has a smooth texture, is not sweet, and goes well with seafood.",
                              "brand": "",
                              "price": "500.00",
                              "images": [
                              
                              ],
                              "product_name": "ยานัด พรีเมี่ยม (YANAD PREMIUM)",
                              "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>13%</strong></p><p><br></p><p>เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต <strong>100% </strong>สดจากไร่ที่ปลูกในจังหวัดภูเก็ต <strong>โดยหมักบ่มถึง 2 ปี </strong>จึงมีความละมุนในรสสัมผัส ไม่หวาน เข้ากันได้ดีกับอาหารซีฟู้ด</p><p><br></p><p>Quantity:<strong> 750 Ml.</strong></p><p>Alcohol: <strong>13%</strong></p><p>Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. After 2 years of fermentation, it has a smooth texture, is not sweet, and goes well with seafood.</p>",
                              "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_1679248082873574000b57a1215t115371c2",
                              "product_code": "ไวน์ขาว",
                              "instant_discount": null,
                              "discounted_price": null,
                              "has_variant": false,
                              "available": 9,
                              "product_category": "Food and Beverage>Beverages",
                              "product_snapshot_id": 16069119,
                              "shop_id": 526717,
                              "gift_option": "NO_GIFT"
                              },
                              {
                              "id": 1004161348,
                              "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 10.5% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต สดจากไร่ที่ปลูกในจังหวัดภูเก็ต และมีสารแอนโทไซยานินที่ให้สีแดงซึ่งเป็นสารต้านอนุมูลอิสระ ที่สกัดมาจากองุ่นแดง ไวน์ขวดนี้จึงมีกลิ่นอายขององุ่นเจือปนอยู่นิดๆ ผ่าน การบ่มถึง 1 ปี มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์ เข้ากันได้ดีกับอาหารทุกประเภท Quantity: 750 Ml. Alcohol: 10.5% Properties: It is a wine made from Phuket pineapples. Fresh from the farms grown in Phuket. And contains anthocyanin, which gives red color and is an antioxidant extracted from red grapes. This wine has a slight grape flavor. It has been aged for 1 year, has a slight sweetness, and is easy to eat, even for those who have never drunk wine. It goes well with all types of food.",
                              "brand": "",
                              "price": "350.00",
                              "images": [
                              "https://obs.line-scdn.net:443/r/ect/ect/image_167924930541749925822dc6633t11537689",
                              "https://obs.line-scdn.net:443/r/ect/ect/image_167924930482822874522dc6632t11537688",
                              "https://obs.line-scdn.net:443/r/ect/ect/image_167924930657291188322dc6634t1153768a",
                              "https://obs.line-scdn.net:443/r/ect/ect/image_1679249307117715375b5789405t1153768b",
                              "https://obs.line-scdn.net:443/r/ect/ect/image_1679249307739553332b5789406t1153768b"
                              ],
                              "product_name": "ยานัด เรด ไวน์ (Red Pineapple Wine)",
                              "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>10.5%</strong></p><p><br></p><p>\t\tเป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต สดจากไร่ที่ปลูกในจังหวัดภูเก็ต และมีสารแอนโทไซยานินที่ให้สีแดงซึ่งเป็นสารต้านอนุมูลอิสระ ที่สกัดมาจากองุ่นแดง ไวน์ขวดนี้จึงมีกลิ่นอายขององุ่นเจือปนอยู่นิดๆ ผ่าน <strong>การบ่มถึง 1 ปี </strong>มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์ เข้ากันได้ดีกับอาหารทุกประเภท</p><p><br></p><p>Quantity: <strong>750 Ml.</strong></p><p>Alcohol: <strong>10.5%</strong></p><p>Properties: It is a wine made from Phuket pineapples. Fresh from the farms grown in Phuket. And contains anthocyanin, which gives red color and is an antioxidant extracted from red grapes. This wine has a slight grape flavor. It has been aged for 1 year, has a slight sweetness, and is easy to eat, even for those who have never drunk wine. It goes well with all types of food.</p>",
                              "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_1679249305865239047b5789404t11537689",
                              "product_code": "ไวน์แดง",
                              "instant_discount": null,
                              "discounted_price": null,
                              "has_variant": false,
                              "available": 10,
                              "product_category": "Food and Beverage>Beverages",
                              "product_snapshot_id": 16069155,
                              "shop_id": 526717,
                              "gift_option": "NO_GIFT"
                              },
                              {
                              "id": 1004161342,
                              "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 10.5% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต 100% สดจากไร่ที่ปลูกในจังหวัดภูเก็ต โดยหมักบ่มถึง 1 ปี มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์เข้ากันได้ดีกับอาหารซีฟู้ด Quantity: 750 Ml. Alcohol: 10.5% Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. It has been aged for 1 year, it has a slight sweetness and is easy to eat, even for people who have never drank wine. It goes well with seafood.",
                              "brand": "",
                              "price": "350.00",
                              "images": [
                              "https://obs.line-scdn.net:443/r/ect/ect/image_16792489961342248846c6b5002t11537554",
                              "https://obs.line-scdn.net:443/r/ect/ect/image_16792489965529234176c6b5003t11537554",
                              "https://obs.line-scdn.net:443/r/ect/ect/image_16792490196942087656c6b5010t1153756b",
                              "https://obs.line-scdn.net:443/r/ect/ect/image_16792489975511262656c6b5004t11537555",
                              "https://obs.line-scdn.net:443/r/ect/ect/image_167924899519261051822de4348t11537553"
                              ],
                              "product_name": "ยานัด ไวท์ ไวน์ (White Pineapple Wine)",
                              "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>10.5%</strong></p><p>\t\tเป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต <strong>100% </strong>สดจากไร่ที่ปลูกในจังหวัดภูเก็ต <strong>โดยหมักบ่มถึง 1 ปี </strong>มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์เข้ากันได้ดีกับอาหารซีฟู้ด</p><p><br></p><p>Quantity: <strong>750 Ml.</strong></p><p>Alcohol: <strong>10.5%</strong></p><p>Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. It has been aged for 1 year, it has a slight sweetness and is easy to eat, even for people who have never drank wine. It goes well with seafood.</p>",
                              "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_16792485575196148336c6a7865t1153739d",
                              "product_code": "ไวน์ขาว",
                              "instant_discount": null,
                              "discounted_price": null,
                              "has_variant": false,
                              "available": 12,
                              "product_category": "Food and Beverage>Beverages",
                              "product_snapshot_id": 16069148,
                              "shop_id": 526717,
                              "gift_option": "NO_GIFT"
                              },
                              {
                              "id": 1004161358,
                              "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 11% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต สดจากไร่ที่ปลูกในจังหวัดภูเก็ต และมีสารแอนโทไซยานินที่ให้สีชมพูซึ่งเป็นสารต้านอนุมูลอิสระ ที่สกัดมาจากองุ่นแดง ไวน์ขวดนี้จึงมีกลิ่นอายขององุ่นเจือปนอยู่นิดๆ ผ่านการบ่มถึง 1 ปี มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์ เข้ากันได้ดีกับอาหารทุกประเภท Quantity: 750 Ml. Alcohol: 11%﻿ Properties: It is a wine made from Phuket pineapples. Fresh from the farms grown in Phuket. and contains anthocyanin, which gives pink color, and is an antioxidant extracted from red grapes. This wine has a slight grape flavor. It has been aged for 1 year, has a slight sweetness, and is easy to eat, even for those who have never drunk wine. It goes well with all types of food.",
                              "brand": "",
                              "price": "300.00",
                              "images": [
                              
                              ],
                              "product_name": "อนานัส โรเซ่ (Ananus Rose)",
                              "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>11%</strong></p><p><br></p><p>\t\tเป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต สดจากไร่ที่ปลูกในจังหวัดภูเก็ต และมีสารแอนโทไซยานินที่ให้สีชมพูซึ่งเป็นสารต้านอนุมูลอิสระ ที่สกัดมาจากองุ่นแดง ไวน์ขวดนี้จึงมีกลิ่นอายขององุ่นเจือปนอยู่นิดๆ <strong>ผ่านการบ่มถึง 1 ปี</strong> มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์ เข้ากันได้ดีกับอาหารทุกประเภท</p><p><br></p><p>Quantity: <strong>750 Ml.</strong></p><p>Alcohol: <strong>11%<span class=\"ql-cursor\">﻿</span></strong></p><p>Properties: It is a wine made from Phuket pineapples. Fresh from the farms grown in Phuket. and contains anthocyanin, which gives pink color, and is an antioxidant extracted from red grapes. This wine has a slight grape flavor. It has been aged for 1 year, has a slight sweetness, and is easy to eat, even for those who have never drunk wine. It goes well with all types of food.</p>",
                              "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_167924982274890530722dd8532t1153788e",
                              "product_code": "ไวน์ชมพู",
                              "instant_discount": null,
                              "discounted_price": null,
                              "has_variant": false,
                              "available": 7,
                              "product_category": "Food and Beverage>Beverages",
                              "product_snapshot_id": 16069175,
                              "shop_id": 526717,
                              "gift_option": "NO_GIFT"
                              },
                              {
                              "id": 1004161355,
                              "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 11% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต 100% สดจากไร่ที่ปลูกในจังหวัดภูเก็ต โดยผ่านการบ่ม 6 เดือน มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์เข้ากันได้ดีกับอาหารซีฟู้ด Quantity: 750 Ml. Alcohol: 11% Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. After aging for 6 months, it has a slight sweetness and is easy to eat, even for those who have never drunk wine. It goes well with seafood.",
                              "brand": "",
                              "price": "300.00",
                              "images": [
                              
                              ],
                              "product_name": "อนานัส ชายนี่ไวท์ (Ananus Shiny White)",
                              "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>11%</strong></p><p><br></p><p>\t\tเป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต <strong>100% </strong>สดจากไร่ที่ปลูกในจังหวัดภูเก็ต <strong>โดยผ่านการบ่ม 6 เดือน</strong> มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์เข้ากันได้ดีกับอาหารซีฟู้ด</p><p><br></p><p>Quantity: <strong>750 Ml.</strong></p><p>Alcohol: <strong>11%</strong></p><p>Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. After aging for 6 months, it has a slight sweetness and is easy to eat, even for those who have never drunk wine. It goes well with seafood.</p>",
                              "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_16792495671829917896c6a8357t1153778f",
                              "product_code": "ไวน์ขาว",
                              "instant_discount": null,
                              "discounted_price": null,
                              "has_variant": false,
                              "available": 10,
                              "product_category": "Food and Beverage>Beverages",
                              "product_snapshot_id": 16069172,
                              "shop_id": 526717,
                              "gift_option": "NO_GIFT"
                              },
                              {
                                    "id": 1004161377,
                                    "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 13% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต 100% สดจากไร่ที่ปลูกในจังหวัดภูเก็ต โดยหมักบ่มถึง 2 ปี จึงมีความละมุนในรสสัมผัส ไม่หวาน เข้ากันได้ดีกับอาหารซีฟู้ด Quantity: 750 Ml. Alcohol: 13% Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. After 2 years of fermentation, it has a smooth texture, is not sweet, and goes well with seafood.",
                                    "brand": "",
                                    "price": "500.00",
                                    "images": [
                                    
                                    ],
                                    "product_name": "ยานัด พรีเมี่ยม (YANAD PREMIUM)",
                                    "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>13%</strong></p><p><br></p><p>เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต <strong>100% </strong>สดจากไร่ที่ปลูกในจังหวัดภูเก็ต <strong>โดยหมักบ่มถึง 2 ปี </strong>จึงมีความละมุนในรสสัมผัส ไม่หวาน เข้ากันได้ดีกับอาหารซีฟู้ด</p><p><br></p><p>Quantity:<strong> 750 Ml.</strong></p><p>Alcohol: <strong>13%</strong></p><p>Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. After 2 years of fermentation, it has a smooth texture, is not sweet, and goes well with seafood.</p>",
                                    "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_1679248082873574000b57a1215t115371c2",
                                    "product_code": "ไวน์ขาว",
                                    "instant_discount": null,
                                    "discounted_price": null,
                                    "has_variant": false,
                                    "available": 9,
                                    "product_category": "Food and Beverage>Beverages",
                                    "product_snapshot_id": 16069119,
                                    "shop_id": 526717,
                                    "gift_option": "NO_GIFT"
                                    },
                                    {
                                    "id": 1004161356,
                                    "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 10.5% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต สดจากไร่ที่ปลูกในจังหวัดภูเก็ต และมีสารแอนโทไซยานินที่ให้สีแดงซึ่งเป็นสารต้านอนุมูลอิสระ ที่สกัดมาจากองุ่นแดง ไวน์ขวดนี้จึงมีกลิ่นอายขององุ่นเจือปนอยู่นิดๆ ผ่าน การบ่มถึง 1 ปี มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์ เข้ากันได้ดีกับอาหารทุกประเภท Quantity: 750 Ml. Alcohol: 10.5% Properties: It is a wine made from Phuket pineapples. Fresh from the farms grown in Phuket. And contains anthocyanin, which gives red color and is an antioxidant extracted from red grapes. This wine has a slight grape flavor. It has been aged for 1 year, has a slight sweetness, and is easy to eat, even for those who have never drunk wine. It goes well with all types of food.",
                                    "brand": "",
                                    "price": "350.00",
                                    "images": [
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_167924930541749925822dc6633t11537689",
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_167924930482822874522dc6632t11537688",
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_167924930657291188322dc6634t1153768a",
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_1679249307117715375b5789405t1153768b",
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_1679249307739553332b5789406t1153768b"
                                    ],
                                    "product_name": "ยานัด เรด ไวน์ (Red Pineapple Wine)",
                                    "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>10.5%</strong></p><p><br></p><p>\t\tเป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต สดจากไร่ที่ปลูกในจังหวัดภูเก็ต และมีสารแอนโทไซยานินที่ให้สีแดงซึ่งเป็นสารต้านอนุมูลอิสระ ที่สกัดมาจากองุ่นแดง ไวน์ขวดนี้จึงมีกลิ่นอายขององุ่นเจือปนอยู่นิดๆ ผ่าน <strong>การบ่มถึง 1 ปี </strong>มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์ เข้ากันได้ดีกับอาหารทุกประเภท</p><p><br></p><p>Quantity: <strong>750 Ml.</strong></p><p>Alcohol: <strong>10.5%</strong></p><p>Properties: It is a wine made from Phuket pineapples. Fresh from the farms grown in Phuket. And contains anthocyanin, which gives red color and is an antioxidant extracted from red grapes. This wine has a slight grape flavor. It has been aged for 1 year, has a slight sweetness, and is easy to eat, even for those who have never drunk wine. It goes well with all types of food.</p>",
                                    "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_1679249305865239047b5789404t11537689",
                                    "product_code": "ไวน์แดง",
                                    "instant_discount": null,
                                    "discounted_price": null,
                                    "has_variant": false,
                                    "available": 10,
                                    "product_category": "Food and Beverage>Beverages",
                                    "product_snapshot_id": 16069155,
                                    "shop_id": 526717,
                                    "gift_option": "NO_GIFT"
                                    },
                                    {
                                    "id": 1004161312,
                                    "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 10.5% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต 100% สดจากไร่ที่ปลูกในจังหวัดภูเก็ต โดยหมักบ่มถึง 1 ปี มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์เข้ากันได้ดีกับอาหารซีฟู้ด Quantity: 750 Ml. Alcohol: 10.5% Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. It has been aged for 1 year, it has a slight sweetness and is easy to eat, even for people who have never drank wine. It goes well with seafood.",
                                    "brand": "",
                                    "price": "350.00",
                                    "images": [
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_16792489961342248846c6b5002t11537554",
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_16792489965529234176c6b5003t11537554",
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_16792490196942087656c6b5010t1153756b",
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_16792489975511262656c6b5004t11537555",
                                    "https://obs.line-scdn.net:443/r/ect/ect/image_167924899519261051822de4348t11537553"
                                    ],
                                    "product_name": "ยานัด ไวท์ ไวน์ (White Pineapple Wine)",
                                    "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>10.5%</strong></p><p>\t\tเป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต <strong>100% </strong>สดจากไร่ที่ปลูกในจังหวัดภูเก็ต <strong>โดยหมักบ่มถึง 1 ปี </strong>มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์เข้ากันได้ดีกับอาหารซีฟู้ด</p><p><br></p><p>Quantity: <strong>750 Ml.</strong></p><p>Alcohol: <strong>10.5%</strong></p><p>Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. It has been aged for 1 year, it has a slight sweetness and is easy to eat, even for people who have never drank wine. It goes well with seafood.</p>",
                                    "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_16792485575196148336c6a7865t1153739d",
                                    "product_code": "ไวน์ขาว",
                                    "instant_discount": null,
                                    "discounted_price": null,
                                    "has_variant": false,
                                    "available": 12,
                                    "product_category": "Food and Beverage>Beverages",
                                    "product_snapshot_id": 16069148,
                                    "shop_id": 526717,
                                    "gift_option": "NO_GIFT"
                                    },
                                    {
                                    "id": 1004161366,
                                    "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 11% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต สดจากไร่ที่ปลูกในจังหวัดภูเก็ต และมีสารแอนโทไซยานินที่ให้สีชมพูซึ่งเป็นสารต้านอนุมูลอิสระ ที่สกัดมาจากองุ่นแดง ไวน์ขวดนี้จึงมีกลิ่นอายขององุ่นเจือปนอยู่นิดๆ ผ่านการบ่มถึง 1 ปี มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์ เข้ากันได้ดีกับอาหารทุกประเภท Quantity: 750 Ml. Alcohol: 11%﻿ Properties: It is a wine made from Phuket pineapples. Fresh from the farms grown in Phuket. and contains anthocyanin, which gives pink color, and is an antioxidant extracted from red grapes. This wine has a slight grape flavor. It has been aged for 1 year, has a slight sweetness, and is easy to eat, even for those who have never drunk wine. It goes well with all types of food.",
                                    "brand": "",
                                    "price": "300.00",
                                    "images": [
                                    
                                    ],
                                    "product_name": "อนานัส โรเซ่ (Ananus Rose)",
                                    "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>11%</strong></p><p><br></p><p>\t\tเป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต สดจากไร่ที่ปลูกในจังหวัดภูเก็ต และมีสารแอนโทไซยานินที่ให้สีชมพูซึ่งเป็นสารต้านอนุมูลอิสระ ที่สกัดมาจากองุ่นแดง ไวน์ขวดนี้จึงมีกลิ่นอายขององุ่นเจือปนอยู่นิดๆ <strong>ผ่านการบ่มถึง 1 ปี</strong> มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์ เข้ากันได้ดีกับอาหารทุกประเภท</p><p><br></p><p>Quantity: <strong>750 Ml.</strong></p><p>Alcohol: <strong>11%<span class=\"ql-cursor\">﻿</span></strong></p><p>Properties: It is a wine made from Phuket pineapples. Fresh from the farms grown in Phuket. and contains anthocyanin, which gives pink color, and is an antioxidant extracted from red grapes. This wine has a slight grape flavor. It has been aged for 1 year, has a slight sweetness, and is easy to eat, even for those who have never drunk wine. It goes well with all types of food.</p>",
                                    "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_167924982274890530722dd8532t1153788e",
                                    "product_code": "ไวน์ชมพู",
                                    "instant_discount": null,
                                    "discounted_price": null,
                                    "has_variant": false,
                                    "available": 7,
                                    "product_category": "Food and Beverage>Beverages",
                                    "product_snapshot_id": 16069175,
                                    "shop_id": 526717,
                                    "gift_option": "NO_GIFT"
                                    },
                                    {
                                    "id": 1004161300,
                                    "description": "ปริมาตร : 750 มล แอลกอฮอล์ล : 11% เป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต 100% สดจากไร่ที่ปลูกในจังหวัดภูเก็ต โดยผ่านการบ่ม 6 เดือน มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์เข้ากันได้ดีกับอาหารซีฟู้ด Quantity: 750 Ml. Alcohol: 11% Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. After aging for 6 months, it has a slight sweetness and is easy to eat, even for those who have never drunk wine. It goes well with seafood.",
                                    "brand": "",
                                    "price": "300.00",
                                    "images": [
                                    
                                    ],
                                    "product_name": "อนานัส ชายนี่ไวท์ (Ananus Shiny White)",
                                    "html_description": "<p>ปริมาตร : <strong>750 มล</strong></p><p>แอลกอฮอล์ล : <strong>11%</strong></p><p><br></p><p>\t\tเป็นไวน์ที่ผลิตจากสับปะรดภูเก็ต <strong>100% </strong>สดจากไร่ที่ปลูกในจังหวัดภูเก็ต <strong>โดยผ่านการบ่ม 6 เดือน</strong> มีความหวานเพียงเล็กน้อย ทานง่าย แม้คนที่ไม่เคยดื่มไวน์เข้ากันได้ดีกับอาหารซีฟู้ด</p><p><br></p><p>Quantity: <strong>750 Ml.</strong></p><p>Alcohol: <strong>11%</strong></p><p>Properties: It is a wine made from 100% Phuket pineapples, fresh from the plantation in Phuket. After aging for 6 months, it has a slight sweetness and is easy to eat, even for those who have never drunk wine. It goes well with seafood.</p>",
                                    "img_url": "https://obs.line-scdn.net:443/r/ect/ect/image_16792495671829917896c6a8357t1153778f",
                                    "product_code": "ไวน์ขาว",
                                    "instant_discount": null,
                                    "discounted_price": null,
                                    "has_variant": false,
                                    "available": 10,
                                    "product_category": "Food and Beverage>Beverages",
                                    "product_snapshot_id": 16069172,
                                    "shop_id": 526717,
                                    "gift_option": "NO_GIFT"
                                    }
                        ]                   

export const review = [
                              {     
                                    comment: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ", 
                                    name: 'yoo baring',
                                    city: 'thai',
                                    score : 4
                              }, 
                              {     
                                    comment: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ", 
                                    name: 'bird baring',
                                    city: 'thai',
                                    score : 3
                              }, 
                              {     
                                    comment: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ", 
                                    name: 'yoo baring',
                                    city: 'Italy',
                                    score : 2
                              },
                              {     
                                    comment: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ", 
                                    name: 'day baring',
                                    city: 'thai',
                                    score : 5
                              },
                              {     
                                    comment: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ", 
                                    name: 'yoo baring',
                                    city: 'Italy',
                                    score : 3
                              }, 
                              {     
                                    comment: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ", 
                                    name: 'bird baring',
                                    city: 'Italy',
                                    score : 2
                              },
                              {     
                                    comment: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy. ", 
                                    name: 'yoora baring',
                                    city: 'thai',
                                    score : 5
                              },

                        ]

export const detailwine = [
                              {     
                                    img: 'images/3401521.png', 
                                    title: 'การเก็บเกี่ยว',
                                    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi hendrerit elit turpis.'
                              },
                              {     
                                    img: 'images/2872086.png', 
                                    title: 'การกรอง',
                                    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi hendrerit elit turpis. '
                              },
                              {     
                                    img: 'images/2727975.png', 
                                    title: 'การหมัก',
                                    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi hendrerit elit turpis. '
                              } 
                        ]

export const post = [
      {
          "id": "112005557370648_604586354779230",
          "message": "#DOGE  ใครไม่ทันรอรับนะ ส่วนใครมีของอยู่ก็ลองพิจารณาดูนะครับ\n=============\n#BINANCE: DOGEUSDT TF 15m\nWebsite : https://hapharmonic.com",
          "created_time": "2022-10-30T12:10:23+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/604586354779230/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/313426010_604574704780395_7300032232659980244_n.png?stp=dst-png_p720x720&_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeEOrMM44vsexoiqzjHrsBURS04DX-dskVpLTgNf52yRWrp8CsU7RjOysoZGIdAug5zGnT8AB8l54zU1hKFmsZMH&_nc_ohc=zONK7-rIHIMAX83Idjx&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfCDiFvv3oojKlPf0LJiXpQJUQz1Hso6IHQ95guIhU3ujQ&oe=6426DB17",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/313426010_604574704780395_7300032232659980244_n.png?stp=dst-png_p720x720&_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeEOrMM44vsexoiqzjHrsBURS04DX-dskVpLTgNf52yRWrp8CsU7RjOysoZGIdAug5zGnT8AB8l54zU1hKFmsZMH&_nc_ohc=zONK7-rIHIMAX83Idjx&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfCDiFvv3oojKlPf0LJiXpQJUQz1Hso6IHQ95guIhU3ujQ&oe=6426DB17"
          ]
      },
      {
          "id": "112005557370648_604364901468042",
          "message": "BTC มาลุ้นกัน ว่าคลื่นลูกที่5 จะมาได้อีกไหม 🚀\n\nWebsite: http://hapharmonic.com",
          "created_time": "2022-10-30T05:03:39+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/604364901468042/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 1560,
                              "src": "https://scontent-ams4-1.xx.fbcdn.net/v/t39.30808-6/313407808_604364204801445_1817474514737970756_n.jpg?stp=cp1_dst-jpg_p720x720&_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGStgIAWl70hZA2AVkipwxZEIQsqDKrgiwQhCyoMquCLLyrsCKr0K_jJEJS_TvN63B2CxlPdOLzZ3uQGxvaTMcc&_nc_ohc=8jXD-A9wFOsAX9s1LSG&_nc_ht=scontent-ams4-1.xx&edm=AKK4YLsEAAAA&oh=00_AfCgvI408YK1cFkGHdbNPfSEyeNgWhzCiltk2gFKNOTduw&oe=642654ED",
                              "width": 720
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams4-1.xx.fbcdn.net/v/t39.30808-6/313407808_604364204801445_1817474514737970756_n.jpg?stp=cp1_dst-jpg_p720x720&_nc_cat=101&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGStgIAWl70hZA2AVkipwxZEIQsqDKrgiwQhCyoMquCLLyrsCKr0K_jJEJS_TvN63B2CxlPdOLzZ3uQGxvaTMcc&_nc_ohc=8jXD-A9wFOsAX9s1LSG&_nc_ht=scontent-ams4-1.xx&edm=AKK4YLsEAAAA&oh=00_AfCgvI408YK1cFkGHdbNPfSEyeNgWhzCiltk2gFKNOTduw&oe=642654ED"
          ]
      },
      {
          "id": "112005557370648_601823675055498",
          "message": "BTC ระยะสั้น 🧐\n=============\n#BINANCE: BTCUSDT TF 4H\nWebsite : https://hapharmonic.com",
          "created_time": "2022-10-26T15:41:31+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/601823675055498/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/312466361_601823368388862_4397006729242426481_n.png?stp=dst-png_p720x720&_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeEH8e0NESsmW_jZRlYqnum48ZH4742QERHxkfjvjZAREcxd3WxEFbJ_XWvGXbpM2xsSKh2xbENUCrpJNh_IZqkU&_nc_ohc=As_gRcMAk60AX8xmVRz&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfBt64qCmSb-EeXmBu9BctMYz3rUy18t-YCeOE7nByz0rg&oe=64265859",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/312466361_601823368388862_4397006729242426481_n.png?stp=dst-png_p720x720&_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeEH8e0NESsmW_jZRlYqnum48ZH4742QERHxkfjvjZAREcxd3WxEFbJ_XWvGXbpM2xsSKh2xbENUCrpJNh_IZqkU&_nc_ohc=As_gRcMAk60AX8xmVRz&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfBt64qCmSb-EeXmBu9BctMYz3rUy18t-YCeOE7nByz0rg&oe=64265859"
          ]
      },
      {
          "id": "112005557370648_594275935810272",
          "message": "EGLD รายวัน🚀 ช่วงนี้ตลาดกำลังนิ่งๆ ก็ต้องคัดกันหน่อย\n=============\n#BINANCE: EGLDUSDT TF 1D\nWebsite : https://hapharmonic.com",
          "created_time": "2022-10-16T13:20:38+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/594275935810272/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/311674259_594273039143895_2004154665977063834_n.png?stp=dst-png_p720x720&_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeFSGWH8xDAQMojmAKTo89Jy_EvMkWt_0Vf8S8yRa3_RV8NYgwiWBKUxdAjCXIGxOCsAnhizZrZeAtMGxwBNW5HQ&_nc_ohc=DBQvoijTW6MAX-edYPp&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDxg5tr8b6VkZzhkJEnFYomi8gJMgjV3nFEzDcGljIrDw&oe=6427A2BB",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/311674259_594273039143895_2004154665977063834_n.png?stp=dst-png_p720x720&_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeFSGWH8xDAQMojmAKTo89Jy_EvMkWt_0Vf8S8yRa3_RV8NYgwiWBKUxdAjCXIGxOCsAnhizZrZeAtMGxwBNW5HQ&_nc_ohc=DBQvoijTW6MAX-edYPp&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDxg5tr8b6VkZzhkJEnFYomi8gJMgjV3nFEzDcGljIrDw&oe=6427A2BB"
          ]
      },
      {
          "id": "112005557370648_584757243428808",
          "message": "BTC อาจเป็นจุดกลับตัวบริเวณนี้ อย่างไรก็ตาม หากรูดลงต่ำว่า  17k  คาดว่าจะดิ่งลงต่อ ขอแค่อย่าหลุดแถวๆ 17k ละกัน\n=============\n#BINANCE: BTCUSDT TF 1D\nWebsite : https://hapharmonic.com",
          "created_time": "2022-10-04T15:51:45+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/584757243428808/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/310899476_584754483429084_8710109865062885473_n.png?stp=dst-png_p720x720&_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeHr5zzJVCwqFqaesMKGRTweg6YzXGzEgaODpjNcbMSBozwtorREWLc8egC8eFxKTboevQYCe5vgkz0JdKgY-pf8&_nc_ohc=NobGYjxvt28AX_elpks&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfAkFSrRVoSIsIZ_MgMYy-plEqvV8gKaeYJzmW7qhJr5yQ&oe=64269CD7",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/310899476_584754483429084_8710109865062885473_n.png?stp=dst-png_p720x720&_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeHr5zzJVCwqFqaesMKGRTweg6YzXGzEgaODpjNcbMSBozwtorREWLc8egC8eFxKTboevQYCe5vgkz0JdKgY-pf8&_nc_ohc=NobGYjxvt28AX_elpks&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfAkFSrRVoSIsIZ_MgMYy-plEqvV8gKaeYJzmW7qhJr5yQ&oe=64269CD7"
          ]
      },
      {
          "id": "112005557370648_579796050591594",
          "message": "LUNA  ฟอร์มตัวใน Bitkub พร้อมแล้วที่จะทยานตัวขึ้นไปอีกครั้ง และหากนี้คือ คลื่น2 เป้าหมายคลื่น3 ก็มีความเป็นไปได้ ที่จะอยู่บริเวณ 0.035 - 0.056  อย่างไรก็ตามโปรดระวัง หาก BTC ยังดื้อ แผนก็อาจจะล้มเหลว\n======================\n#BITKUB : #LUNATHB TF 4H\nWebsite: https://hapharmonic.com",
          "created_time": "2022-09-28T13:12:14+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/579796050591594/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/309376592_579790990592100_7882254858824228001_n.png?stp=dst-png_p720x720&_nc_cat=104&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeGLIsT-xbFwc2oQ--Tbo4Yszj4tQiWIAGzOPi1CJYgAbILYLOMlm8ApYUcVpM8sZcxyIiFI05WSR0acZ-oOOK6K&_nc_ohc=NaL4diyeUJoAX-ekyUu&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfBhqvKEvFrCixXFQUJsfbCT06iIYm_GmvH0r9gpugdTOg&oe=64267A63",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/309376592_579790990592100_7882254858824228001_n.png?stp=dst-png_p720x720&_nc_cat=104&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeGLIsT-xbFwc2oQ--Tbo4Yszj4tQiWIAGzOPi1CJYgAbILYLOMlm8ApYUcVpM8sZcxyIiFI05WSR0acZ-oOOK6K&_nc_ohc=NaL4diyeUJoAX-ekyUu&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfBhqvKEvFrCixXFQUJsfbCT06iIYm_GmvH0r9gpugdTOg&oe=64267A63"
          ]
      },
      {
          "id": "112005557370648_579600173944515",
          "message": "เอาละ เขียวอยู่ตัวเดียว ช่วงนี้ใครถือ USDT ก็ยิ้มกันไป 😁",
          "created_time": "2022-09-28T08:49:42+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/579600173944515/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 336,
                              "src": "https://scontent-ams4-1.xx.fbcdn.net/v/t39.30808-6/309266645_579599623944570_6878802284014475184_n.png?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeFYO7av1dm5RSEi0txv2StiDC6STgCd-QAMLpJOAJ35AM88_xEFqLAh1FWLAdOeDNa_WqB687scibevheu66DQe&_nc_ohc=rH5CN_JihcsAX_BUj-t&_nc_ht=scontent-ams4-1.xx&edm=AKK4YLsEAAAA&oh=00_AfB5oSu4J_677zbFygtKaD_dsI5yajZd5OIPsGQtKcONDQ&oe=6426F872",
                              "width": 688
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams4-1.xx.fbcdn.net/v/t39.30808-6/309266645_579599623944570_6878802284014475184_n.png?_nc_cat=111&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeFYO7av1dm5RSEi0txv2StiDC6STgCd-QAMLpJOAJ35AM88_xEFqLAh1FWLAdOeDNa_WqB687scibevheu66DQe&_nc_ohc=rH5CN_JihcsAX_BUj-t&_nc_ht=scontent-ams4-1.xx&edm=AKK4YLsEAAAA&oh=00_AfB5oSu4J_677zbFygtKaD_dsI5yajZd5OIPsGQtKcONDQ&oe=6426F872"
          ]
      },
      {
          "id": "112005557370648_576373824267150",
          "message": "เป้าหมาย #DOGE นี้แค่เพียงจุดเริ่มต้นเท่านั้น🚀🚀\n======================\n#BINANCE : #DOGEUSDT TF 1D\nWebsite: https://hapharmonic.com",
          "created_time": "2022-09-24T04:27:35+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/576373824267150/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/308751221_576372320933967_6463910949337461653_n.png?stp=dst-png_p720x720&_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeE_UITU1zDxZJ0Nv0tWsNbTDkkj-vqzvJAOSSP6-rO8kFuRng23yrQKdahIBUAZebNGO384zv09rPc8QRzSFa9h&_nc_ohc=oHvOlg0lUsMAX9PkwlT&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfASCvHK5XXY1MmgwG1nxjUPtNc8OT0WSRweGJyrkAo0xQ&oe=6427256B",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/308751221_576372320933967_6463910949337461653_n.png?stp=dst-png_p720x720&_nc_cat=100&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeE_UITU1zDxZJ0Nv0tWsNbTDkkj-vqzvJAOSSP6-rO8kFuRng23yrQKdahIBUAZebNGO384zv09rPc8QRzSFa9h&_nc_ohc=oHvOlg0lUsMAX9PkwlT&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfASCvHK5XXY1MmgwG1nxjUPtNc8OT0WSRweGJyrkAo0xQ&oe=6427256B"
          ]
      },
      {
          "id": "112005557370648_575668264337706",
          "message": "ETH 🚀🚀 สำหรับโครงสร้าง Harmonic ขณะนี้อยู่ในโซน PRZ เตรียมพร้อมที่จะกลับตัวแล้ว รวมถึง level 1-3 ก็บ่งบอกถึงแนวโน้มการกลับตัวทั้งหมด อีกทั้งสถานะคลื่น #Elliottwave  ในตอนนี้ชี้ไปหาคลื่นที่3 ที่อาจจะเกิดขึ้นในเร็วๆนี้ มารอลุ้นกันว่าจะเป็นเป็นเช่นไรต่อไป\n======================\n#BINANCE : #ETHUSDT TF 1D\nWebsite: https://hapharmonic.com",
          "created_time": "2022-09-23T06:50:37+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/575668264337706/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/308411990_575664511004748_7505657567957212897_n.png?stp=dst-png_p720x720&_nc_cat=106&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeFdLXKO45djw1t4LqJ8xWVOW_6gCqu1Qi5b_qAKq7VCLnw1Brnk-AKt0gvJXrgYmSsMIPXDpl6Mp_0tDgJ5Rclk&_nc_ohc=HXVraTjciMAAX-J3FLP&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfCbjh66CeyhnN-eRS7yZOHT3aXQWjXj2w-NZExlgkl6-g&oe=6427BB72",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/308411990_575664511004748_7505657567957212897_n.png?stp=dst-png_p720x720&_nc_cat=106&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeFdLXKO45djw1t4LqJ8xWVOW_6gCqu1Qi5b_qAKq7VCLnw1Brnk-AKt0gvJXrgYmSsMIPXDpl6Mp_0tDgJ5Rclk&_nc_ohc=HXVraTjciMAAX-J3FLP&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfCbjh66CeyhnN-eRS7yZOHT3aXQWjXj2w-NZExlgkl6-g&oe=6427BB72"
          ]
      },
      {
          "id": "112005557370648_572766107961255",
          "message": "XAUUSD 4Hr.\n=============\n#FXOEPN : #XAUUSD TF 4H\nWebsite : https://hapharmonic.com",
          "created_time": "2022-09-19T05:33:23+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/572766107961255/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/307568604_572765237961342_8607602626139570329_n.png?stp=dst-png_p720x720&_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeF0FS2F87ga1ZyzlScmkNzs9BVAYjOtdiP0FUBiM612I4EpRYyq3BRLPyeScWCRZ4n5DABVO3g36MsKLOJ7Zb6e&_nc_ohc=RRyLHFmHqMoAX9BMe2p&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDYzufKuuvoQ5mvnNTNEuV1fIveT-bK0VSSOK9BPA6FxQ&oe=64280692",
                              "width": 1389
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/307568604_572765237961342_8607602626139570329_n.png?stp=dst-png_p720x720&_nc_cat=110&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeF0FS2F87ga1ZyzlScmkNzs9BVAYjOtdiP0FUBiM612I4EpRYyq3BRLPyeScWCRZ4n5DABVO3g36MsKLOJ7Zb6e&_nc_ohc=RRyLHFmHqMoAX9BMe2p&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDYzufKuuvoQ5mvnNTNEuV1fIveT-bK0VSSOK9BPA6FxQ&oe=64280692"
          ]
      },
      {
          "id": "112005557370648_566586258579240",
          "message": "#ALICE🚀 เช้านี้\n=============\n#BINANCE: ALICEUSDT TF 1D\nWebsite : https://hapharmonic.com",
          "created_time": "2022-09-10T01:08:50+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/566586258579240/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/306002652_566585958579270_1279778368272472349_n.png?stp=dst-png_p720x720&_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeGpiKOoq76jY4WmSiBNj5RuyE-mm3e90ULIT6abd73RQqK21GXH1bzgn0j-g70m7m9N6eDqVlPSIUbcpdUh6bBA&_nc_ohc=aYE3vYOgy98AX__62qe&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfAT12HEPVViLg1CT7asvtUtgyHb83DBw5ABAxlr03ZzCA&oe=6427A145",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/306002652_566585958579270_1279778368272472349_n.png?stp=dst-png_p720x720&_nc_cat=108&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeGpiKOoq76jY4WmSiBNj5RuyE-mm3e90ULIT6abd73RQqK21GXH1bzgn0j-g70m7m9N6eDqVlPSIUbcpdUh6bBA&_nc_ohc=aYE3vYOgy98AX__62qe&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfAT12HEPVViLg1CT7asvtUtgyHb83DBw5ABAxlr03ZzCA&oe=6427A145"
          ]
      },
      {
          "id": "112005557370648_566306461940553",
          "message": "ช่วงนี้เพจปลอมเยอะหากใครโดนแชร์โปรไฟล์แสดงว่าเป็นเพจปลอมนะครับให้เข้าไปเพจที่แชร์แล้วไปกดปุ่ม3จุดด้านบนแล้วกดรายงานเพจขอบคุณครับ🙏",
          "created_time": "2022-09-09T15:11:44+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/566306461940553/"
      },
      {
          "id": "112005557370648_566269648610901",
          "message": "BTC ในที่สุด 🚀🚀🚀\n=============\n#BINANCE: BTCUSDT TF 4H\nWebsite : https://hapharmonic.com",
          "created_time": "2022-09-09T14:10:55+0000",
          "permalink_url": "https://www.facebook.com/112005557370648/posts/566269648610901/",
          "attachments": {
              "data": [
                  {
                      "media": {
                          "image": {
                              "height": 720,
                              "src": "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/306072978_566269071944292_6984856353299805732_n.png?stp=dst-png_p720x720&_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeHrA_EsxQ3fXF0maZwk57VMlmI0HrZXxCmWYjQetlfEKXVfeCOQy-_w_oO8pUfO-DulXiSoOyQCT0-tzZ1bfAik&_nc_ohc=SrX0rm4CjPkAX9guC_Y&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfB4aKVGjmuxaC0oRomquz8Lo0nfJQGP6k3iKDrb8y_i3A&oe=6426DC7B",
                              "width": 1266
                          }
                      }
                  }
              ]
          },
          "images": [
              "https://scontent-ams2-1.xx.fbcdn.net/v/t39.30808-6/306072978_566269071944292_6984856353299805732_n.png?stp=dst-png_p720x720&_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_eui2=AeHrA_EsxQ3fXF0maZwk57VMlmI0HrZXxCmWYjQetlfEKXVfeCOQy-_w_oO8pUfO-DulXiSoOyQCT0-tzZ1bfAik&_nc_ohc=SrX0rm4CjPkAX9guC_Y&_nc_ht=scontent-ams2-1.xx&edm=AKK4YLsEAAAA&oh=00_AfB4aKVGjmuxaC0oRomquz8Lo0nfJQGP6k3iKDrb8y_i3A&oe=6426DC7B"
          ]
      }
  ]


 export const faq = [
                              {     id: 'fag01',
                                    title: 'ใช้สับปะรดอะไร',
                                    details: 'ใช้สับปะรดภูเก็ตแท้ๆ อย่างเดียว',
                              },
                              {     id: 'fag02',
                                    title: 'ผลิตที่ไหน',
                                    details: 'ผลิตในภูเก็ต โรงงานอยู่บ้านบางโรง อำเภอถลาง',
                              },
                              {     id: 'fag03',
                                    title: 'ทำไมมีสีแดงกะชมพู',
                                    details: 'เราใช้แอนโทไซยานิน ซึ่งสกัดจากองุ่นแดง มาใช้เป็นส่วนผสมเพิ่มให้สีแดงกับสีชมพู',
                              },
                              {     id: 'fag04',
                                    title: 'มีขายที่ไหนบ้าง',
                                    details: 'มีวางที่บูธ สินค้าโอทอปถลาง ในห้างโลตัสถลาง กับขายในเพจภูเก็ตไวน์',
                              },
                              {     id: 'fag05', 
                                    title: 'มีบริการส่งมั้ย',
                                    details: 'มีบริการจัดส่งทั่วไทย',
                              }   
                        ]