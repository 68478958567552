import Footer from "../components/Footer";
import Header from "../components/Header";
import { useLocation } from "react-router-dom";
import { CardReview, CardWine, CardAbout, CardBanner } from "../components/Card";
import { review, about, menu } from "../mock";

function About (){
      const location = useLocation()
      const path = menu.filter(e=> e.path === location.pathname)[0]
      return (
      <>
      <Header key={'jhggbcd'} lo={location.pathname}/>
            <CardBanner items={path}/>
            <section className="total-body sub-pages d-block">
            <div className="about-page comon-services-pge">
                  <div className="about-sec-1">
                  <div className="container">
                        <div className="row row-cols-1 row-cols-lg-2">
                        <div className="col">
                        <figure>
                              <img src="images/out.jpg" alt="about"/>
                        </figure>
                        </div>
                        <div className="col">
                        <h6> Story ของเรา</h6>
                        <h2> พันธุ์ไวน์ดั้งเดิมสำหรับผู้ชื่นชอบ </h2>
                        <p>&emsp;&emsp; Phuket pineapple is a pineapple from the Malacca Queen group, which is the same cultivar as sweet pineapple. and Phu Lae pineapple grown in the north, and Trat See Thong pineapple grown in the east. Characteristics of this type of pineapple. There will be a small fruit of about 1–2 kg. The flesh has a firm texture, a juicy yellow color, crispy flesh, a fragrant yellow-orange peel, and a very sweet taste. It is considered the identity of Phuket, and the province has registered a geographic indication (GIP) dated November 27, 2009.</p>
                        <p>&emsp;&emsp; But due to economic problems and the declining price of produce (pineapples) in the past 3–4 years, pineapple farmers in Phuket are experiencing the same problem, which is that the price is not as good as expected. It also faces problems with competitors from other provinces that come to take the market, such as pineapples from Phang Nga, Krabi, Trat, etc., which are all pretending to be Phuket pineapples. confuse consumers Despite the government's efforts to limit the use of the term "Pineapple Phuket" to pineapples grown on Phuket. As for pineapples grown in other places, use the name "Phuket pineapple", but it does not bear fruit. because there is no serious enforcement, thus making consumers unable to distinguish which one is real and which is fake. Each shop has to say that it's a Phuket pineapple because if it's said to be something else, it's hard to sell.</p>
                        <p>&emsp;&emsp; With all these problems, the group of pineapple farmers in Phuket has tried to process the produce to raise the price, such as pineapple jam and pineapple juice, but our group has seriously tried the initiative to make pineapple wine since 2017 and also gone to study. Making wine at the school where it was offered. Then they came back to develop through trial and error until the Phuket pineapple wine had a good taste. therefore registered as a company called "Phuket Wine" to help pineapple growers in Phuket. Because our wines are made only from real Phuket pineapples, they have a unique aroma.</p>
                  </div>
                  </div>
                  </div>
                  </div>
                  <CardWine/>
                  <div className="offer-div-sec my-5">
                  <div className="container">
                  <h2 className="text-center mb-5"> ทำไมถึงไว้วางใจเรา? </h2>
                  <div className="row row-cols-2 gy-4 gy-lg-0 gx-lg-4 row-cols-md-2 row-cols-lg-4">
                        {about.map((e, i) => { 
                              return <CardAbout key={i} items={e}/>
                        })}
                  </div>
                  </div>
                  </div>
                  <div className="testimonal-sec pt-5 position-relative">  
                        <div className="container">
                        <h6 className="text-center"> <span></span> รีวิว </h6>
                        <h2 className="text-center">จากลูกค้า </h2>
                              <div className="silder-div-text mt-5 pb-5">
                              <div className="w-100 overflow-hidden">
                                    <div className="best-sl-m owl-carousel owl-theme">
                                    {review.map((e, i) => { 
                                    return  i < 6 ? <CardReview key={i} items={e}/> : <></>
                                    })}                          
                                    </div>
                              </div>
                              </div>
                        </div>
                        <div className="tesimonial-sec-div">
                              <figure>
                                    <img src="images/ttt/11.png" alt="pic"/>
                              </figure>
                        </div>
                  </div>     
            </div>
            </section>
      <Footer/>
      </>
      )

}

export default  About;