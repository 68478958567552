import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getfacebookpost } from "../Api";
import { CardBanner } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loading from "../components/Loading";
import { menu } from "../mock";

function Contact() {
      const loca = useLocation()
      const path = menu.filter(e=> e.path === loca.pathname)[0]
      const [postFacebook, setPostFacebook] = useState([])
      const [loading, setLoading] = useState(false)

      useEffect(() => {
        const fetchFacebook = async () => {
          const result = await getfacebookpost();
            setPostFacebook(result.pageInfo);
            setLoading(true)
        }; 
        Promise.all([
        fetchFacebook()
        ])
      }, []);
     const { emails, phone } = postFacebook
     let address = ''

     if (postFacebook.location !== undefined || postFacebook.location != null) {
              const { street, city, country, zip } = postFacebook.location
                  address = `${street} ${city} ${country} ${zip}`
      }
      else { address = 'Phuket Thailand' }

  return (
      <>
            { loading?  <div>
            <Header key={'nbrezfx'} lo={loca.pathname}/>
            <CardBanner items={path}/>
            <section className="total-body sub-pages d-block">
                  <div className="blog-page comon-services-pge">
                        <div className="container">                
                        <div className="row g-lg-5">
                              <div className="col-md-5 cm-text-n">
                                    <h2 className="mb-0">ช่องทางติดต่อ</h2>
                                    <ul className="list-unstyled mt-4">
                                    <li className="d-flex">
                                    <span className="icon-c"><i className="fas fa-phone-square-alt"></i></span> 
                                    <span className="text-c">  โทรหาเรา:
                                          <small className="d-block">{phone}</small> 
                                    </span>
                                    </li>
                                    <li className="d-flex">
                                          <span className="icon-c"><i className="fas fa-envelope"></i></span> 
                                          <span className="text-c">  
                                          อีเมล: 
                                          <small className="d-block">{emails}</small> 
                                          </span>
                                    </li>
                                    <li className="d-flex">
                                          <span className="icon-c"><i className="fas fa-map-marker-alt"></i></span> 
                                          <span className="text-c"> ที่ตั้ง: 
                                          <small className="d-block">{address}</small> 
                                          </span>
                                    </li>
                                    </ul>                         
                              </div>
                              <div className="col-md-7">
                                    <div className="conatct-form-div mb-5">                            
                                    <img src="images/cont.PNG" alt="contact" className="img-fluid"/>
                                    </div>
                              </div>
                        </div>
                        </div>         
                  </div>
            </section>
            <Footer/>
            </div> : <Loading/> }
      </>
  )
}

export default Contact;
