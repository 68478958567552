import { Routes, Route, useLocation } from 'react-router-dom'
import About from './screen/About';
import Blog from './screen/Blog';
import BlogDetail from './screen/BlogDetail';
import Contact from './screen/Contact';
import Faq from './screen/Faq';
import Home from './screen/Home';
import Adm from './screen/Adm';
// import Loading from './screen/Loading';
import Product from './screen/Product';
import ProductDetail from './screen/ProductDetail';
import Promotion from './screen/Promotion';
import CustomerChat from './components/CustomerChat';
import RequireAuth from './screen/RequireAuth';


function App() {
  const location = useLocation()
  return (
    <>
    {location.pathname !== '/adm'?<CustomerChat /> : null }{/* นำ CustomerChat component มาแทรกใน JSX */}
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/product" element={<Product/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/blog" element={<Blog/>}/>
      <Route path="/promotion" element={<Promotion/>}/>
      <Route path="/faq" element={<Faq/>}/>
      <Route path="/adm" element={<Adm/>}/>
      <Route element={<RequireAuth />}>
            {/* ใส่เงื่อนไขเพื่อไม่แสดง <CustomerChat /> ในส่วนของ <Route path="/adm" element={<Adm/>}/> */}
            {/* {location.pathname !== '/adm' && <CustomerChat />} */}
      <Route path="/product-detail" element={<ProductDetail/>}/>
      <Route path="/blog-detail" element={<BlogDetail/>}/>
    </Route>
      {/* <Route path="/loading" element={<Loading/>}/> */}
    </Routes>
    </>
  )
}

export default App;
