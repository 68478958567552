const Footer = () => {

      const content = (
         <>  
            <footer className="float-start w-100">
            <div className="subsribe-div py-5 pb-3"></div>
            <hr/>
            <div className="container">
                  
                  <div className="row row-cols-1 row-cols-md-4 footer-bottom-div">
                  <div className="col">
                        <div className="comon-footer">
                        <img src="images/logo.png" alt="logo"/>
                        <p className="text-white col-lg-9 mt-3">ไวน์ที่ผลิตจากสับปะรดภูเก็ต 100%</p>
                        </div>
                  </div>

                  <div className="col">
                        <div className="comon-footer ">
                        <h5> Quick Links </h5>
                        <ul className="list-unstyled">
                        <li> <a href="/">Home</a>  </li>
                        <li> <a href="/product">สินค้า</a></li>
                        <li> <a href="/promotion">โปรโมชั่น</a></li>
                        </ul>
                        </div>
                  </div>
                  
                  <div className="col">
                        <div className="comon-footer">
                        <h5> About </h5>
                        <ul className="list-unstyled">
                        <li> <a href="/about">เกี่ยวกับเรา</a>  </li>
                        <li> <a href="/faq"> FAQ </a></li>
                        <li> <a href="/blog"> Blog </a> </li>
                        
                        </ul>
                        </div>
                  </div>

                  <div className="col">
                        <div className="comon-footer">
                        <h5>Contact</h5>
                        <ul className="list-unstyled">
                        <li> <a href="/contact">ติดต่อเรา</a> </li>
                        </ul>
                        </div>
                  </div>

                  <div className="col">
                        <div className="comon-footer">
                        <h5>สำหรับ Admin</h5>
                        <ul className="list-unstyled">
                        <li> <a href="/adm" target="_blank">สำหรับ Admin</a></li>
                        </ul>
                        </div>
                  </div>

                  </div>
            </div>
            
                  <div className="copy-text">
                  <div className="container">
                        <div className="d-grid  justify-content-between text-center d-table m-auto">
                        <div className="text-white"> 2023 © Phuket Wine. All rights reserved. </div>
                        
                        </div>
                  </div>
                  </div>

            </footer>
         </> 
      )
      return content
  }
  export default Footer