import '../App.css'
const Loading=()=> {

  return (
      <>
      <div className="col-left">
        <svg width="60" height="110" xmlns="http://www.w3.org/2000/svg" className="svg-glass">
            <g fill="none" fill-rule="evenodd">
              <path id="wine-layer-1" d="M23.029 48.208c8.136 0 21.202-10.342 21.474-19.297.261-8.558.681-16.233-6.484-15.368-6.16.744-23.987 11.124-31.37 9.427-3.068-.706-4.331-3.917-5.127-3.719-.376.093-.335 3.753-.335 5.948.335 13.905 13.755 23.01 21.842 23.01z" fill="#9B1431"/>
              <path id="wine-layer-2" d="M23.029 48.208c8.136 0 21.202-10.342 21.474-19.297.261-8.558.681-16.233-6.484-15.368-6.16.744-23.987 11.124-31.37 9.427-3.068-.706-4.331-3.917-5.127-3.719-.376.093-.335 3.753-.335 5.948.335 13.905 13.755 23.01 21.842 23.01z" fill="#650A1F"/>
              <path fill="#CACACA" d="M23 79l-2-28h4z"/>
              <path d="M19.5 48.5h7.06" stroke="#A3A3A3" stroke-width="3" stroke-linecap="square"/>
              <path fill="#D7D7D7" d="M23 89l10 5H13z"/>
              <path d="M5.41 91.744c3.206.986 5.122 1.524 5.749 1.614 2.218.317 5.341.614 11.841.59 3.037-.01 8.694.017 10.918-.426 1.492-.296 3.42-.832 5.787-1.607" stroke="#A6A6A6" stroke-width="2" stroke-linecap="square"/>
              <path d="M9.364.5C6.942 3.702 5.326 6.27 4.517 8.194c-1.154 2.745-2.796 8.765-3.027 10.848-.215 1.94-.222 8.096 0 9.952.394 3.297 2.736 8.383 6.315 11.584 1.518 1.358 3.053 2.439 4.93 3.553.334.199.67.393 1.09.634l1.01.575c.778.447 1.282.757 1.723 1.069.62.439 1.063.856 1.368 1.314 1.245 1.874 3.81 37.07 3.089 39.115-.525 1.489-2.915 2.078-8.761 2.786l-1.547.185c-4.35.52-6.324.962-6.415 1.415-.283 1.402 2.567 2.581 7.322 3.281 3.789.558 20.165.558 23.517.002 3.636-.604 6.127-2.03 5.62-3.18-.161-.363-1.747-.762-4.997-1.237-.166-.024-2.017-.29-2.573-.373-4.946-.738-7.359-1.423-8.171-2.79-.972-1.636 1.775-37.308 3.192-39.224.323-.437.765-.841 1.364-1.267.437-.311.93-.62 1.693-1.067l.985-.575c.409-.239.737-.433 1.064-.632 1.827-1.11 3.338-2.186 4.855-3.536 3.554-3.162 5.932-8.36 6.335-11.686.222-1.839.213-7.89-.001-9.9-.26-2.453-1.67-8.033-2.89-10.84-.912-2.1-2.493-4.67-4.742-7.7h-27.5z" stroke="#BEBEBE"/>
              <path d="M38.232 32.104c-.449-6.838-1.557-12.538-3.324-17.101C33.141 10.44 30.956 5.773 28.353 1h6.555c2.34 3.119 4.013 7.522 5.019 13.209 1.006 5.686.44 11.652-1.695 17.895z" fill-opacity=".35" fill="#FFF"/>
            </g>
          </svg>
          <span>LOADING</span>
        </div>

        <div className="col-right">
          <img src="https://www.wsjwine.com/images/us/common/animation/loading_icon/Loading_Icon.gif" alt=""/>
        </div>
      </>
            // <div className='loader'>
            //       <div className='blob'></div>
            //       <div className='blob'></div>
            //       <div className='blob'></div>
            //       <div className='blob'></div>
            //       <div className='blob'></div>
            //       <div className='blob'></div>
            //       <div className='blob'></div>
            // </div>
  )
}

export default Loading;


