import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getfacebookpost } from "../Api";
import { BtnProduct } from "../components/Button";
import { CardBanner, PostIMG } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loading from "../components/Loading";
import { setFormatDate } from "../function/function";

function BlogDetail() {
      const location = useLocation()
      const path = [{ "name": "Blog Details", "params": "Blog" }]
      const [facebook, setFacebook] = useState([])
      const [loading, setLoading] = useState(false)

      const items = location.state
      const { message, attachments, created_time, permalink_url, id } = items[0]
      const name = items[1] 
      const username = items[2] === undefined ? items[1] : items[2] 
      const image = attachments === undefined ? 'images/109076-cypruswine-Cyprus-Maratheftiko-Shiraz-wine-748x549.jpg' : attachments.data[0].media.image.src 
      const date = setFormatDate(created_time)

      useEffect(() => {
        const fetchData = async () => {
          const result = await getfacebookpost();
          const face = result.postdata.filter(e => e.id !== id) 
          setFacebook(face.slice(0,3))
          setLoading(true)
        };
        Promise.all([
        fetchData()
        ])
      }, []);

  return (
    <> { loading ? 
        <div>
        <Header key={'pkllhu'} lo={location.pathname}/>
        <CardBanner items={path[0]}/>
        <section className="total-body mt-5 pt-3 d-block">
            <div className="blog-details-page comon-services-pge">
                <div className="container">
                    <div className="row gx-lg-5">
                        <div className="col-sm-7 col-lg-8">
                            <div className="blog-post">
                                <figure><img style={{maxHeight: 500}} src={image} alt="post"/></figure>
                                <div className="d-lg-flex justify-content-between share-div">
                                    <ul className="list-unstyled d-flex mt-3 mt-lg-0">
                                    <li> <i className="far fa-user"></i> {username}</li>
                                    <li> <i className="far fa-calendar-alt"></i> {date}</li>
                                    </ul>
                                    <BtnProduct title={'Share'} icon={'fas fa-share-alt'} href={permalink_url}/>
                                </div>
                                <h2>{name}</h2>
                                <p>&emsp;&emsp;&emsp;{message}</p>
                            </div>             
                        </div>
                        <div className="col-sm-5 col-lg-4">
                            <div className="recent-post-div mt-5">
                                <h2 className="mb-4"> Facebook Post </h2>
                                <div className="recent-post-div-insiide">
                                { loading ? facebook.map((el, i) => {
                                    return <PostIMG key={i} items={el}/>
                                }): null}
                                </div>
                            </div>             
                        </div>
                    </div>
                </div> 
            </div>
        </section>
        <Footer/>
        </div>: <Loading/>
        }
    </>
  )
}


export default BlogDetail;
