import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { ApiProductAll } from "../Api";
import { CardBanner, CardPromotion } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { menu } from "../mock";
import { getProducts, promotions } from "../Api";
import Loading from "../components/Loading";

function Promotion () {
      const location = useLocation()
      const path = menu.filter(e=> e.path === location.pathname)[0]
      const [product, setProduct] = useState([])
      const [loading, setLoading] = useState(false)

      useEffect(() => {
        const fetchData = async () => {
          const result = await getProducts();
          setProduct(result);
            setTimeout(()=> {
              setLoading(true)
            }, 2000)
        }; 
        Promise.all([
          fetchData()
        ])
      }, []);


      const content = (
        <div>
          <Header key={'cfsaze'} lo={location.pathname}/>
          <CardBanner items={path}/>
          <section className="total-body sub-pages d-block">
            <div className="faq-page-total comon-services-pge">
                  <div className="container">
                  <h6 className="text-center cm-sub">โปรโมชั่น</h6>
                  <h2 className="text-center">PROMOTION</h2>
                        <div className="container">
                          <div className="row row-cols-1 row-cols-lg-3 gx-lg-5 pt-5 justify-content-center align-items-center">
                                {product.length === 0 ? <h2 style={{color: 'red'}}>ไม่มีโปรโมชั่นในขณะนี้</h2> : product.map((e, i) => { return e.promotion == 'มี'? <CardPromotion key={i} items={e}/> : null })}
                          </div>
                        </div>              
                  </div>  
            </div>
          </section>
          <Footer/>
        </div>
      )

      return loading ? content : <Loading/>
}

export default Promotion;
