import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getfacebookpost } from "../Api";
import { CardBanner, CardFacebook } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Loading from "../components/Loading";
import { menu } from "../mock";

function Blog() {
      const location = useLocation()
      const path = menu.filter(e=> e.path === location.pathname)[0]
      const [postFacebook, setPostFacebook] = useState([])
      const [loading, setLoading] = useState(false)
      const [load, setLoad] = useState(false)

      useEffect(() => {
        const fetchFacebook = async () => {
          const result = await getfacebookpost();
            setPostFacebook(result);
            setLoad(true)
            setTimeout(()=> {
              setLoading(true)
            }, 1000)
        }; 
        Promise.all([
        fetchFacebook()
        ])
      }, []);

      const post = postFacebook.postdata
      const postinfo = postFacebook.pageInfo

  return (
    <>
    {loading ?
      <div>
        <Header key={'kjuvce'} lo={location.pathname}/>
        <CardBanner items={path}/>
        <section className="total-body sub-pages d-block">
        <div className="blog-page comon-services-pge">
              <div className="container">
              <h6 className="text-center cm-sub"> ข่าว & บทความ </h6>
              <h2 className="text-center"> บล็อกของเรา</h2>
              <div className="row row-cols-1 row-cols-lg-2 gx-lg-5">
              { load ? post.map((e, i) => { 
                        return  <CardFacebook key={i} items={e} page={postinfo}/>
                    }) : null
              }
              </div>
              </div>
        </div>
        </section>
        <Footer/>
      </div> : <Loading/> }
    </>
  )
}

export default Blog;
