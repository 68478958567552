import { BtnProduct, BtnShop } from "./Button";
import { useNavigate } from "react-router-dom";
import { setFormatDate, setFormatDateGMT, setFormatTime } from "../function/function";

export const CardPromotion = ({items}) => { 
      const { nameTH, nameEng, promotiondetails } = items;

      const content = (
            <div className="col">
                  <div className="d-flex mt-4 ">
                  <a rel="noreferrer" href='https://lin.ee/U6OmyCh' target="_blank" className="comon-offer mb-5 mb-lg-0">
                  <figure>
                  <img src={"images/istockphoto-1183406603-170667a.jpg"} alt="bg"/>
                  </figure>
                  <h5 className="col-9 m-auto"> 
                        <span className="d-block">{promotiondetails}</span>
                        {`${nameTH}`}<br/>{`${nameEng}`}
                  </h5>
                  </a>
                  </div>
            </div>
      ) 
      return content    
}

export const CardProduct = ({items, col}) => { 
      const {nameTH, nameEng, img1, price, discount, sellprice} = items
      const navigate = useNavigate()
      const headelClick = (items) => {
            navigate('/product-detail', {state: items})
      }

      const handleRefresh = () => {
                  headelClick(items)  
                  window.location.reload();         
          }


      const content = (
            <div className={col}>
                  <div className="produc-div" onClick={()=>handleRefresh(items)}>
                        <div className="products-box">
                           {discount >! 0?<div className="offer-text-div">เหลือ ฿ {Math.round(sellprice)}</div>: null}
                        <figure><img src={img1 == '-'? 'images/noimg.png': img1} alt="pic"/></figure>
                        <div className="hover-show-bn">
                              <BtnProduct title={''} style={'comon-hv-bn'} icon={'fa-solid fa-square-arrow-up-right'} />
                        </div>
                        </div>
                        <div className="ps-details text-center">
                              <a className="cate-text d-table text-center m-auto">organic</a>
                              <a className="products-titel m-auto">{nameTH} {nameEng}</a>
                              <div className="d-flex align-items-center justify-content-center">
                                    {discount <! 0? <h5 className="price-text ">฿ {Math.round(price)}</h5> 
                                    : <h5 className="price-text " style={{textDecoration: 'line-through'}}>฿ {Math.round(price)}</h5>}
                              </div>
                        </div>
                  </div>
            </div> 
      )
      return content 
}

export const CardSlice = ({items, num}) => { 

      const { nameTH, nameEng, img1} = items;
      const navigate = useNavigate()
      const headelClick = (items) => {
            navigate('/product-detail', {state: items})
      }

      const handleRefresh = () => {
                  window.location.reload();
          }
      const content = (
            <div className={`slider slide-${num} d-md-flex`}>
            <div className="img-div">                   
              <div className="spring-animation">
              <div className="row" style={{position: 'relative'}}>
                  <img src="images/about-his.png" alt="bmn" style={{marginTop: '120px', left: -50}}/>
                  <img src={img1} alt="bn1" style={{position: 'absolute', top: -50, left: -50, width: 250}}/>
              </div>

              </div>
            </div>                
              <div className="col-md-6">
                  <div className="slider-content">
                    <h4>ลองลิ้มรสไวน์ของเรา</h4>
                    <h2>มีความเข้มข้นสูง
                    <span className="d-block">  Phuket Wine </span></h2>
                    <p>{nameTH} {nameEng}</p>
                    <div onClick={handleRefresh}>
                    <BtnShop title={'Shop Now '} style={'buy-now-btn'} icon={'fas fa-receipt'} onPress={()=> headelClick(items)}/>
                    </div>
                  </div>
              </div>            
          </div>
      )
      return content 
}

export const CardWine = () => {
      const content = (
            <div className="first-order-div">
            <div className="container">
              <h6 className="text-center text-white h3"> ไวน์ยานัด</h6>
                <h2 className="text-center text-white">ไวน์ที่ผลิตจากสับปะรดภูเก็ต 100%</h2>
                <p className="text-center text-white col-md-7 m-auto"> ด้วยสุรายอดนิยมและสุราหายากที่มีให้เลือกมากมาย เราจึงเป็นร้านค้าครบวงจรสำหรับทุกความต้องการเครื่องดื่มแอลกอฮอล์ของคุณ </p>
                <BtnProduct title={'ซ๊อปเลย'} style={'btn shop-bn-offer'} href={'/product'}/>
            </div>
            </div>
      )
      return content
}

export const CardReview = ({items}) => { 
      const { name, comment, score, city } = items

      const stars = Array.from({ length: score }).map((_, i) => (
            <i key={i} className="fas fa-star"></i>
          ));
      const content = (
            <div className="item m-auto">
                  <div className="comon-div-ts">
                  <h5>{comment}</h5>
                        <span className="start">
                              {stars}
                        </span>
                  </div>
                  <div className="d-flex mt-4 ">
                  <h4>{name}<span className="d-block"> -{city} </span>  </h4>
                  </div>
            </div>
      )
      return content 
}

export const CardDetailWine= ({items}) => { 
      const { img, title, detail } = items

      const content = (
            <div className="comon-special">
            <div className="d-flex justify-content-between">
                  <div className="col-3 col-md-2">
                  <figure className="icon-sp">
                        <img src={img} alt="bnerpic"/>
                  </figure>
                  </div>
                  <div className="col-9 col-md-10">
                  <h5>{title}</h5>
                  <p>{detail}</p>
                  </div>
            </div>                 
            </div>
      )
      return content 
}

export const CardAbout = ({items}) => { 
      const { img, title } = items

      const content = (
            <div className="col">
            <div className="comon-our-text text-center">
                  <figure className="shadow">
                  <img src={img}alt="of"/>
                  </figure>
                  <h5>{title}</h5>
            </div>
            </div>
      )
      return content 
}

export const CardBanner = ({items}) => { 
      const {name, params} = items
      const content = (
            <div className="banner-part subpage-banner">
            <div className="container">
              <div className="banner-div-content d-grid align-content-center">
                 <h1 className="text-center">{name === 'บล๊อก'? 'Blog':name}</h1>
                 <ul className="list-unstyled d-flex justify-content-center">
                   <li href="/"> Home </li>
                   { params? <li> <a>{params}</a> </li>: null}
                   <li> <a>{name}</a> </li>
                 </ul>
              </div>
            </div>
          </div>
      )
      return content 
}

export const CardFacebook =({items, page})=> {

      const { name, username } = page
      const { message, attachments, created_time } = items
      const image = attachments === undefined ? 'images/109076-cypruswine-Cyprus-Maratheftiko-Shiraz-wine-748x549.jpg' : attachments.data[0].media.image.src 
      // const date = created_time.split('T')[0]
      const date = setFormatDate(created_time)
      const birthday = new Date(created_time);
      const day = birthday.getDate()
      const getMonth = birthday.toLocaleString('en-US', { month: 'short' });
      const navigate = useNavigate()
      const headelClick = (items) => {
            navigate('/blog-detail', {state: [items, name, username]})
      }

      // const handleRefresh = () => {
      //       window.location.reload();
      //     }
      
      const content = (
            <div className="item">
            <div className="comon-news-part">
            <div className="comon-pic-news">
                  <figure className="position-relative">
                  <a> <img src={image} alt="picb"/> </a>
                  </figure>
                  <div className="date-text">{day}<span className="d-block">{getMonth}</span> </div>
            </div>
            <div className="dtails-idv-text">
                  <ul className="list-unstyled d-flex">
                        <li><a> <i className="fa fa-user-alt"></i>{username === undefined || username === null? name : username}</a></li>
                        <li> <a> <i className="fas fa-calendar-week"></i> {date}</a></li>
                  </ul>
                  <h5>{name}</h5>
                  <p>{message.slice(0,60)+`...`}</p>
                  <BtnProduct title={'read more'} style={'btn read-more-bn text-uppercase'} href={'/blog-detail'} onPress={()=>headelClick(items)} />
            </div>
            </div>
      </div>
      )
      return content

}

export const CardFaq =({items})=> {
      const { title, details, id } = items
      const content = (
      <div className="col">
            <div className="accordion-item">
            <h2 className="accordion-header" id={`heading${id}`}>
            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${id}`} aria-expanded="true" aria-controls={`collapse${id}`}>
                  {title}
            </button>
            </h2>
            <div id={`collapse${id}`} className="accordion-collapse collapse show" aria-labelledby={`heading${id}`} data-bs-parent="#accordionExample">
            <div className="accordion-body">
                  <p>{details}</p>            
            </div>
            </div>
            </div>
      </div>
      )
      return content
}

export const ProductIMG=({items, num, style})=> {
      const content = (
        <div className="item">
            <figure className={style}>
                { num == 1? <img src={items} alt={`re${num}`} width={'50%'}/>:
                <img src={items} alt={`re${num}`}/>}
            </figure>
        </div>
      )
      return content
}

export const PostIMG=({items})=> {
      const { message, attachments, created_time, permalink_url} = items
      const image = attachments === undefined ? 'images/109076-cypruswine-Cyprus-Maratheftiko-Shiraz-wine-748x549.jpg' : attachments.data[0].media.image.src
      const date = setFormatDate(created_time)

      const content = (
                        <>
                        <a href={permalink_url} target="_blank" rel="noreferrer"  className="d-flex w-100 justify-content-between align-items-center">
                              <figure>
                                 <img src={image}  alt="post"/>
                              </figure>
                              <h5>{date}<br/>{message.slice(0,20)+`...`}</h5>
                        </a>
                        </>
      )
      return content
}