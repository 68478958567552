import { useLocation } from "react-router-dom";
import { CardBanner, CardFaq } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { menu, faq } from "../mock";

function Faq() {
      const location = useLocation()
      const path = menu.filter(e=> e.path === location.pathname)[0]
  return (
    <div>
      <Header key={'reshcyx'} lo={location.pathname}/>
      <CardBanner items={path}/>
      <section className="total-body sub-pages d-block">
      <div className="faq-page-total comon-services-pge">
            <div className="container">
            <h6 className="text-center"> มีคำถาม? </h6>
            <h2 className="text-center"> คำถามที่พบบ่อย </h2>
                  <div className="accordion mt-5" id="accordionExample">
                  <div className="row row-cols-1 row-cols-lg-2">
                       {faq.map((e, i)=><CardFaq key={i} items={e} />)}
                  </div>
                  </div>                 
            </div>  
      </div>
      </section>
      <Footer/>
    </div>
  )
}

export default Faq;
