import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getProducts, products } from "../Api";
import { BtnProduct } from "../components/Button";
import { CardBanner, CardProduct, ProductIMG } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery"
import 'owl.carousel';
import OwlCarousel from 'react-owl-carousel';

function ProtuctDetail() {
      const location = useLocation()
      const path = [{ "name": "Products Details", "params": "Products" }]
      const [pro, setPro] = useState([])

      useEffect(() => {
        window.onpopstate = () => {
            window.location.reload(); 
            window.scrollTo(0, 0);
        };
        window.scrollTo(0, 0);
        const fetchData = async () => {
          const result = await getProducts();
          if (location.state && location.state) {
            setPro(result);
          }
          return () => {
            const elementToRemove = document.getElementById('element-to-remove');
            if (elementToRemove) {
              elementToRemove.parentNode.removeChild(elementToRemove);
            }
          }
        };
        Promise.all([
        fetchData()
        ])
      }, [location.state]);


    const items = location.state
    const { nameTH, nameEng, price, sellprice, alcohol, detailTH, detailEng, volume, img1, img2, img3, img4, img5, img6, ID} = items
    const imgs = [img1 ,img2, img3, img4, img5, img6]
    const images = Array.from(new Set(imgs)).filter(item => item !== "-")
    const details = pro.filter(e => e.ID !== ID)   
    
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true, // กำหนดให้สไลด์อัตโนมัติ
    autoplaySpeed: 2000, // กำหนดความเร็วในการสไลด์อัตโนมัติ
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // กำหนดให้สไลด์อัตโนมัติ
    autoplaySpeed: 3000, // กำหนดความเร็วในการสไลด์อัตโนมัติ
  };

  return (
    <>
    <div id="element-to-remove">
      <Header key={'ioghvtq'} lo={location.pathname}/>
            <CardBanner items={path[0]}/>
            <section className="total-body pt-5 sub-pages bg-white d-block">
              <div className="products-details-sec"> 
                <div className="container">   
                      <div className="row gx-5">
                          <div className="col-lg-6 mt-4">
                              <div style={{maxWidth: '50%', margin:  'auto'}}>
                              <div className="related-prodcus-slide">
                                <Slider {...setting}>
                                        {images.length === 0 ? <ProductIMG items={'images/noimg.png'} num={`1`} style={`thum-pic-slide`}/>
                                        : images.map((e, i )=> <ProductIMG key={i} items={e} num={i+1} style={`thum-pic-slide`}/>)}
                                </Slider>
                              </div>
                                  {/* <div className="mian-img-show">
                                    <div id="sync1" className="owl-carousel owl-theme">
                                      {images.length === 0 ? <ProductIMG items={'images/noimg.png'} num={`1`} style={`mian-ppic`}/>
                                      : images.map((e, i )=> <ProductIMG key={i} items={e} num={i+1} style={`mian-ppic`}/>)}
                                    </div>
                                  </div>                           
                                    <div id="sync2" className="owl-carousel owl-theme">
                                        {images.length === 0 ? <ProductIMG items={'images/noimg.png'} num={`1`} style={`thum-pic-slide`}/>
                                        : images.map((e, i )=> <ProductIMG key={i} items={e} num={i+1} style={`thum-pic-slide`}/>)}
                                    </div>  */}
                              </div>
                          </div>
                          <div className="col-lg-6 mt-4">
                              <div className="menu-dl-right">
                                  <h2>{nameTH} {nameEng}</h2>
                                  <h3>฿ {Math.round(sellprice)}</h3> 
                                  <p>ปริมาตร : {volume} มล</p>
                                  <p>แอลกอฮอล์ : {alcohol} %</p>
                                  <p>{detailTH}</p>
                                    <div className="sel-size mb-3">
                                      <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                          <input type="checkbox" className="btn-check" id="btncheck1" autoComplete="off"/>
                                          <label className="btn btn-outline-primary ms-lg-0" htmlFor="btncheck1">{volume} มล</label>
                                      </div>
                                    </div>
                                  <h5 className="mt-3"> Share This : <a href="https://www.facebook.com/PhuketPineappleWine?mibextid=LQQJ4d"> <i className="fab fa-facebook-f"></i> </a> </h5>
                                  <a rel="noreferrer" href={`https://lin.ee/U6OmyCh`} target="_blank"><BtnProduct title={'ซื้อ'} style={'btn ad-cart-bn'} icon={'fas fa-shopping-cart'}/></a>
                              </div>
                          </div>
                      </div>
                      <div className="bottom-details mt-5">
                        <h2> Description </h2>
                        <hr/>
                        <p>Volume : {volume} ml</p>
                        <p>Alcohol : {alcohol} %</p>
                        <p>{detailEng} </p>
                      </div>
                </div> 
              </div>
                <div className="rl-div mt-5 float-start w-100">
                  <div className="container">
                    <h2 className="mt-5">Recent Products</h2>
                    <div className="related-prodcus-slide mt-4">
                      <Slider {...settings}>
                        {details.map((e, i) => (
                          <CardProduct key={i} items={e}/>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
            </section>
      <Footer/>
    </div> 
    </>
  )
}

export default ProtuctDetail;



