import { useState } from "react";
import Loading from "../components/Loading";

function Adm() {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loading />}
      <iframe
        src="https://script.google.com/macros/s/AKfycbzhN3-iVYVkqHmmmIoweSgp__wqd3XVu2-f2xonmAB7D2Sh8T_rdzMjbhVuQdBJIlbj/exec"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          border: "none",
          margin: 0,
          padding: 0,
          overflow: "hidden",
          zIndex: 999999
        }}
        onLoad={handleLoad}
      ></iframe>
    </>
  );
}

export default Adm;
