import { menu } from '../mock'
import { Menu } from './Button'
const Header = (location) => {
  const lo = location.lo
      const content = (
         <>  
         <header className="float-start w-100">  
            <div className="mn-head">
                <div className="container">
                  <nav className="navbar navbar-expand-lg navbar-light align-items-center justify-content-between">
                         <a className="navbar-brand" href='/'>
                           <img src="images/logo.png" alt="logo" className="mn-logo"/>
                           <img src="images/logo-sc.png" alt="logo" className="sc-logo"/>
                         </a>
                         <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobile-menu" aria-controls="offcanvasExample">
                           <span className="navbar-toggler-icon"></span>
                         </button>
                         {/* <div className="collapse navbar-collapse " id="navbarSupportedContent">
                         <ul className="navbar-nav mx-auto mb-2 mb-lg-0 align-items-lg-center">
                            {menu.map((e, i) => { return <Menu key={i} style={'nav-item'} style1={`nav-link ${lo === e.path || lo === e.path1 ?'active' : null}`} e={e}/> })}
                         </ul>
                         </div> */}

                          <div className="collapse navbar-collapse " id="navbarSupportedContent">
                          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 align-items-lg-center">
                            {menu.map((e, i) => {
                              return <Menu key={i} style={'nav-item'} style1={`nav-link ${lo === e.path || lo === e.path1 ?'active' : null}`} e={e}/>
                            })}
                           
                            <li className="nav-item">
                              <a className="nav-link" href="/adm" target="_blank">
                                สำหรับ Admin
                              </a>
                            </li>

                          </ul>
                        </div>

                     </nav>
                </div>
            </div>
         </header>
        <div className="offcanvas offcanvas-start mobile-menu-div" tabIndex="-1" id="mobile-menu" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">         
              <button type="button" className="close-menu" data-bs-dismiss="offcanvas" aria-label="Close">
                <span> Close </span> <i className="fas fa-long-arrow-alt-right"></i>
              </button>
            </div>            
              <div className="offcanvas-body">
                <div className="head-contact">
                  <a href="/" className="logo-side">
                  <img src="images/logo-sc.png" alt="logo"/>
                  </a>
                  <div className="mobile-menu-sec mt-3">
                      <ul className="list-unstyled">
                      {menu.map((e, i) => { return <Menu key={i} style={`${lo === e.path || lo === e.path1 ?'active-' : null}m`} style1={``} e={e}/> })}
                      <li className="nav-item">
                              <a className="nav-link" href="/adm" target="_blank">
                                สำหรับ Admin
                              </a>
                            </li>
                      </ul>
                  </div>
                </div>
              </div>
        </div>
       </> 
      )
      return content
  }
  export default Header
