import { CardDetailWine, CardFacebook, CardProduct, CardPromotion, CardReview, CardSlice, CardWine } from "../components/Card";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { detailwine, review } from "../mock";
import { products , getfacebookpost, filterPro, promotions, getProducts } from "../Api"
import { useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { BtnProduct } from "../components/Button";



function Home () {
    const location = useLocation()
    const [product, setProduct] = useState([])
    const [postFacebook, setPostFacebook] = useState([])
    const [load, setLoad] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      window.onpopstate = () => {
            window.location.reload();
        };
        window.scrollTo(0, 0);
      const fetchProduct = async () => {
            const result = await getProducts();
            setProduct(result);
            setLoading(true);
          };
      const fetchFacebook = async () => {
        const result = await getfacebookpost();
          setPostFacebook(result);
          setLoad(true)
      }; 
      Promise.all([
      fetchFacebook(),
      fetchProduct()
      ])
    }, []);


    const dis_pro = product.filter(e => parseInt(e.price) !== parseInt(e.sellprice))
    const post = postFacebook.postdata
    const postinfo = postFacebook.pageInfo
    const sliderRef = useRef(null);

    function handleNext() {
      const radios = Array.from(sliderRef.current.querySelectorAll('input[class*="slide-radio"]'));
      const activeRadio = sliderRef.current.querySelector('input[class*="slide-radio"]:checked');
      let currentIndex = radios.indexOf(activeRadio);
      const radiosLength = radios.length;
  
      if (currentIndex < radiosLength - 1) {
        radios[currentIndex + 1].checked = true;
      } else {
        radios[0].checked = true;
      }
    }
  
    useEffect(() => {
      const interval = setInterval(handleNext, 3000);
      return () => clearInterval(interval);
    }, []);


  const content = (
    <div>
   
     <Header key={'xzdrex'} lo={location.pathname}/>
      <div className="banner-part float-start w-100" >
           <div className="container">
             <div className="css-slider-wrapper" ref={sliderRef}>
              <input type="radio" name="slider" className="slide-radio1" defaultChecked id="slider_1"/>
              <input type="radio" name="slider" className="slide-radio2"  id="slider_2"/>
              <input type="radio" name="slider" className="slide-radio3"  id="slider_3"/>
               <div className="slider-pagination">
                <label htmlFor="slider_1" className="page1">
                </label>
                <label htmlFor="slider_2" className="page2">    
                </label>
                <label htmlFor="slider_3" className="page3">          
                </label>             
               </div>
               {loading? product.slice(0, 3).map((e, i) => { 
                        return <CardSlice key={i} items={e} num={i+1}/> 
                   }): <></>}
             </div>
           </div>
      </div>
      <section className="total-body d-block float-start w-100">
      <div className="top-offer-div-sec">
      <div className="container">
            <div className="row row-cols-1 row-cols-lg-3 gx-lg-5 pt-5 justify-content-center align-items-center">
                  {product.slice(0, 3).map((e, i) => { return e.promotion == 'มี'? <CardPromotion key={i} items={e}/> : null })}
            </div>
      </div>
      </div>
      <div className="Prodcuts-div py-5">
            <div className="container py-4">
            <h6 className="text-center"> <span></span> ผลิตภัณฑ์ล่าสุดของเรา </h6>
            <h2 className="text-center text-white comon-heading"> ล่าสุด  </h2>
            <div className="products-slide">
            <div className="filter-container">                
                  <div className="row g-md-5 mt-0 row-cols-1 row-cols-md-2 row-cols-lg-4 mt-5">
                  {product.slice(0, 8).map((e, i) => { 
                        return  <CardProduct key={i} items={e} col={'col'}/>
                   })}
                  </div>
            </div>
            </div>
            </div>
      </div>
      
      <div className="home-about position-relative py-5">
            <div className="container">  
            <div className="row gx-lg-5 row-cols-1 row-cols-md-2">
                  <div className="col position-relative">
                  <figure>
                     <img src="images/ttt/3.png" alt="product thumbnail"/>
                  </figure>
                  </div>
                  <div className="col">
                  <h6> ประสบการณ์ </h6>
                  <h2> เรื่องราวเบื้องหลัง <br/> ไวน์ของเรา </h2>
                  <p>&emsp;&emsp; Phuket pineapple is a pineapple from the Malacca Queen group, which is the same cultivar as sweet pineapple. 
                    and Phu Lae pineapple grown in the north, and Trat See Thong pineapple grown in the east. 
                    Characteristics of this type of pineapple. There will be a small fruit of about 1–2 kg. 
                    The flesh has a firm texture, a juicy yellow color, crispy flesh, a fragrant yellow-orange peel, 
                    and a very sweet taste. It is considered the identity of Phuket, 
                    and the province has registered a geographic indication (GIP) dated November 27, 2009.</p>
                  <BtnProduct title={'อ่านเพิ่มเติม'} style={'btn about-bn'} icon={'fas fa-long-arrow-alt-right'} href={'/about'}/>
            </div>
            </div>
            </div>
      </div>

      <div className="our-production-div py-5">
      <div className="container">
            <h6 className="text-center"> อย่าพลาดวันนี้</h6>
            <h2 className="text-center text-white comon-heading"> ข้อเสนอสุดพิเศษ  </h2>
            <div className="row g-md-5 mt-0 row-cols-1 row-cols-md-2 row-cols-lg-4 ">
                  {dis_pro.slice(0, 8).map((e, i) => { 
                        return <CardProduct key={i} items={e} col={'col'}/>
                  })}
            </div>
      </div>
      </div>   

      <div className="choose-bread py-5">
            <div className="container">
            <div className="row row-cols-1 row-cols-lg-2 flex-column-reverse flex-lg-row"> 
                  <h6>ไวน์ธรรมชาติ 100% </h6>
                  <h2>เยี่ยมชมและเข้าร่วมกับเราเพื่อชิม </h2>   
                  <div className="col">
                  {detailwine.map((e, i) => { 
                        return  <CardDetailWine key={i} items={e}/>
                  })}
                  <BtnProduct title={'Visit the winery'} style={'btn about-bn'} icon={'fas fa-long-arrow-alt-right'}/>
                  </div>
                  <div className="col">
                  <figure className="middle-big-pic">
                     <img src="images/ttt/2.png" alt="wine"/>
                  </figure>
                  </div>
            </div>
            </div>
      </div> 
      <CardWine/>
      <div className="testimonal-sec pt-5 position-relative"> 
      <div className="container">
            <h6 className="text-center"> <span></span>รีวิว </h6>
            <h2 className="text-center">จากลูกค้า </h2>
            <div className="silder-div-text mt-5 pb-5">
                  <div className="w-100 overflow-hidden">
                  <div className="best-sl-m owl-carousel owl-theme">
                        {review.slice(0, 6).map((e, i) => { 
                              return <CardReview key={i} items={e}/>
                        })}
                  </div>
                  </div>
            </div>
      </div>
      <div className="tesimonial-sec-div">
            <figure>
            <img src="images/ttt/11.png" alt="pic"/>
            </figure>
      </div>
      </div>
      <div className="news-sec-div">
            <div className="container">
                  <h6 className="text-center"> <span></span> ข่าว & บทความ</h6>
                  <h2 className="text-center"> บทความล่าสุดของเรา </h2>
                  <div className="row row-cols-1 row-cols-md-3 row-cols-lg-3">
                  { load ? post.slice(0, 3).map((e, i) => { 
                      return <CardFacebook key={i} items={e} page={postinfo}/>
                  }) : null
                  }
                  </div>
            </div>
      </div>
      </section>
      <Footer/>
    </div>
  )

  return content
}

export default Home;
