import { useLocation, Navigate, Outlet } from "react-router-dom"

const RequireAuth = () => {
    const location = useLocation()
    const { pathname, state } = location
    return pathname === '/product-detail' && state === null || pathname === '/blog-detail' && state === null
      ? <Navigate to="/" state={{ from: location }} replace /> 
      : <Outlet /> 
}

export default RequireAuth
